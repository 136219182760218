import styled from 'styled-components'

export const WrapperCategoryBtn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  width: 100%;

  .category-info {
    .name {
      font-weight: bold;
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.red._600};
    }
    .id,
    .type {
      font-size: 0.875rem;
      font-weight: bold;
      color: ${({ theme }) => theme.colors.gray._500};
    }
  }

  button {
    width: 15rem;
    line-height: 1.5;
    background-color: ${({ theme }) => theme.colors.red._500};
    border: none;
    font-weight: bold;
    font-size: 1rem;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
    color: ${({ theme }) => theme.colors.white};
    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }
`

// ---------------------------------
// CATEGORY MODAL
// ---------------------------------
export const WrapperCategoryModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  label {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.blue._800};
    margin-bottom: 0.5rem;
  }

  .search-category {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
    }

    .input-category {
      input[type='search'] {
        margin-top: 0rem;
        font-size: 1rem;

        &:focus {
          border: 1px solid ${({ theme }) => theme.colors.gray._500};
          background-color: ${({ theme }) => theme.colors.gray._100};
          appearance: none;
          outline: 0;
        }
        &::placeholder {
          color: ${({ theme }) => theme.colors.gray._700};
          font-size: 1rem;
          font-style: italic;
        }
      }

      .input-search__loading {
        .loading {
          margin-top: -0.5rem;
        }

        .clear-icon {
          position: absolute;
          float: right;
          margin-left: auto;
          right: 1%;
          margin-top: 0.5rem;
          cursor: pointer;
          &:hover {
            color: ${({ theme }) => theme.colors.red._600};
          }
        }
      }
    }
  }

  .btn-search {
    width: 10rem;
    border: none;
    border-radius: 0.5rem;
    height: 2.3rem;
    padding: 0rem 1rem;
    margin-top: 1rem;

    background-color: ${({ theme }) => theme.colors.red._600};
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;

    transition: 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
`
