import { SurveyQuestionOptionsProps } from 'components/Fields/SurveyFields/SurveySelect'
import { SurveyQuestionModel } from 'entities'

export const setOptionsFromQuestions = (list: SurveyQuestionModel[]) => {
  const newList: any[] = []

  list.sort((a, b) => (a.positionOrder) - (b.positionOrder)).forEach((question) => {
    if (question.options && question.options.length > 0) {
      const options: SurveyQuestionOptionsProps[] = []
      question.options.forEach((option, index) => {
        options.push({ value: option, weight: index })
      })
      newList.push({
        ...question,
        options
      })
    } else {
      newList.push(question)
    }
  })
  return newList
}
