import { Fragment } from 'react'
import { AttributeOptionModel, SurveyQuestionModel } from '../../entities'
import { RadioField, StarsField, TextareaField, TextField } from 'components/Fields'

export const setOptions = (list: string[] | null): AttributeOptionModel[] => {
  const _optionValues: AttributeOptionModel[] = []
  if (list && list.length > 0) {
    list.forEach((opt, index) => {
      _optionValues.push({
        id: crypto.randomUUID(),
        title: opt,
        value: opt,
        attributes: [],
        weight: index,
        propertyId: null,
        categoryId: null,
        type: null,
        group: null,
        categoryName: null,
        openingStatus: null
      })
    })
  }
  return _optionValues
}

type Props = {
  questions: SurveyQuestionModel[]
}

const SurveyInputsToRender = ({ questions }: Props) => {
  return (
    <>
      {questions.length > 0 &&
        questions
          .sort((a, b) => a.positionOrder - b.positionOrder)
          .map((question) => (
            <Fragment key={question.id}>
              {question.type === 'stars' && (
                <StarsField
                  attribute={{
                    id: question.id.toString(),
                    question: question.question,
                    required: question.required,
                    optionsSelectElement: []
                  }}
                  scaleLimit={question.scaleLimit ?? 5}
                />
              )}
              {question.type === 'textarea' && (
                <TextareaField
                  attribute={{
                    id: question.id.toString(),
                    question: question.question,
                    required: question.required,
                    optionsSelectElement: []
                  }}
                />
              )}
              {question.type === 'text' && (
                <TextField
                  attribute={{
                    id: question.id.toString(),
                    question: question.question,
                    required: question.required,
                    optionsSelectElement: []
                  }}
                />
              )}
              {question.type === 'radio' && (
                <RadioField
                  attribute={{
                    id: question.id.toString(),
                    question: question.question,
                    required: question.required,
                    optionsSelectElement: setOptions(question.options)
                  }}
                  handleOptionSelected={() => {}}
                />
              )}
            </Fragment>
          ))}
    </>
  )
}

export default SurveyInputsToRender
