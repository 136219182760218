import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Breadcrumb, Card, Layout } from '../../components'
import { useGetCardList } from '../../hooks'
import { CardModel } from 'entities/cardModels'
import { getItemSelected } from './utils'

import { ContainerService } from './styles'

/*
Card levels:
Level 0: All the cards at home page (url: '/')
Level 1: All the cards children of the card level 0 (url: '/service/:cardId')
Level 2: All the cards children of the card level 1 (url: '/service/:cardIdL1/:cardId')
Level 3: All the cards children of the card level 2 (url: '/service/:cardIdL1/:cardIdL2/:cardId')

Where:
cardId: is the ID of the card selected
cardIdL2: is the ID of the card level 2 previous selected
cardIdL1: is the ID of the card level 1 previous selected
*/
export const Service = () => {
  const { cardId, cardIdL1, cardIdL2 } = useParams()
  const navigate = useNavigate()
  const { cardList } = useGetCardList()
  const [cardSelected, setCardSelected] = useState<null | CardModel>(null)

  const getUrl = (card: CardModel): string => {
    if (cardIdL2) {
      // Link for redirect to form
      if (card?.forms && card?.forms?.length > 0) {
        return `/ticket/new/${card.forms[0].id}`
      }
    }
    if (cardIdL1) {
      // Link for redirect to level 3 cards
      return `/service/${cardIdL1}/${cardSelected?.id as string}/${card.id}`
    }
    if (cardId) {
      // Link for redirect to level 2 cards.
      return `/service/${cardSelected?.id as string}/${card.id}`
    }
    return ''
  }

  useEffect(() => {
    if (cardList.length > 0) {
      const card = getItemSelected({
        list: cardList,
        idL1: cardIdL1,
        idL2: cardIdL2,
        id: cardId as string
      })

      if (!card) {
        navigate('/notification')
        return
      }

      if (card?.children?.length === 0 && card?.forms?.length === 0) {
        navigate('/notification')
        return
      }

      if (card?.children?.length === 0) {
        if (cardId && !cardIdL1 && !cardIdL2) {
          if (card?.forms?.length > 0) {
            navigate(`/ticket/new/${card?.forms[0]?.id}`, { state: { goToHome: true } })
          }
          return
        }
        if (card?.forms?.length > 0) {
          navigate(`/ticket/new/${card?.forms[0]?.id}`, { state: { twoStep: true } })
        }
        return
      }

      setCardSelected(card)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, cardList])

  return (
    <Layout
      title={cardSelected?.name ?? ''}
      isOverflowY={cardSelected?.children ? cardSelected?.children?.length > 5 : false}
    >
      <ContainerService>
        <Breadcrumb titlePage={cardSelected?.name} />
        <div className="service-content">
          <div className="service-cards">
            {cardSelected?.children.map((card: CardModel) => (
              <Fragment key={card.id}>
                {card.isEnable ? <Card key={card.id} card={card} url={getUrl(card)} /> : null}
              </Fragment>
            ))}
          </div>
        </div>
      </ContainerService>
    </Layout>
  )
}
