import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IconPicker } from '..'
import { ErrorMessage, FormIconField } from './styles'

type Props = {
  name: string
  isRequired: boolean
  defaultValue?: IconProp
  toggleInfoModal: () => void
}

const IconField = ({
  name,
  isRequired,
  defaultValue = '' as IconProp,
  toggleInfoModal
}: Props) => {
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors
  } = useFormContext()

  const handleIcon = (icon: string) => {
    return icon
  }

  const handleError = (error: string | null) => {
    if (!error) {
      clearErrors(name)
      return
    }
    setError(name, { type: 'pattern', message: error })
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { onChange } }) => (
        <FormIconField>
          <label htmlFor={name} className="icon-label">
            Selecione o ícone ou digite o nome do ícone
            <button
              type='button'
              onClick={toggleInfoModal}
              className="btn-info"
              title="Obter informação de como usar este campo"
            >
              <FontAwesomeIcon icon={faCircleInfo} />
            </button>
          </label>
          <IconPicker
            defaultIcon={defaultValue }
            onChange={(value) => {
              onChange(handleIcon(value))
            }}
            handleError={(error) => {
              handleError(error)
            }}
          />
          {errors[name]?.type !== 'required' && <ErrorMessage>{errors[name]?.message?.toString()}</ErrorMessage>}
          {errors[name]?.type === 'required' && (
        <ErrorMessage>* Campo obrigatório</ErrorMessage>
          )}
        </FormIconField>
      )}
    />
  )
}

export default IconField
