import axios from 'axios'
import { UserAuthenticationModel } from '../entities'

const url = process.env.REACT_APP_API_AUTH

export const api = axios.create({
  baseURL: url
})

export const authenticate = async (
  username: string,
  password: string
): Promise<UserAuthenticationModel> => {
  const response = await api.post('/authenticate', { username, password })
  return response.data
}
