import { useState } from 'react'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'components'
import { CardModel } from '../../entities'
import { editCard } from 'services/api'
import { ModalBodyWarning } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  cardSelected: CardModel
}

const EnableCardModal = ({ toggleModal, cardSelected, updateData }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const dto: CardModel = {
        ...cardSelected,
        isEnable: !cardSelected?.isEnable
      }
      const response = await editCard(dto)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥,  ocorreu um erro ao editar o card')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title={cardSelected?.isEnable ? 'Desativar card' : 'Ativar card'}
      handleCloseModal={toggleModal}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor='#ea580c'
    >
      <ModalBodyWarning>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faExclamation} />
          </span>
          {cardSelected?.isEnable ? (
            <p>Deseja desativar o card: </p>
          ) : (
            <p>Deseja ativar o card: </p>
          )}
        </div>
        <p className="name">{cardSelected?.name}</p>
        {cardSelected?.isEnable ? (
          <span className="warning-msg">
            {/* <FontAwesomeIcon icon={faTriangleExclamation} /> */}
            <p>
              Ao desativar este card todos os subcards dependentes não poderão ser visualizados!
            </p>
          </span>
        ) : (
          <span className="warning-msg">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <p>Ao ativar este card todos os subcards dependentes serão visualizados!</p>
          </span>
        )}
      </ModalBodyWarning>
    </ModalContent>
  )
}

export default EnableCardModal
