import { useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { SurveyIdentifierType, SurveyQuestionModel, SurveyTemplateModel } from '../../entities'
import ModalContent from 'components/Modal/ModalContent'
import { ModalBodyForm } from 'components/Modal/styles'
import { SurveyFieldList, TextareaField, TextField } from 'components/Fields'
import { ModalWrapper } from 'pages/Admin/styles'
import PreviewSurveyModal from './PreviewSurveyModal'
import { ISurveyQuestionProps } from 'components/Fields/SurveyFields/SurveyFieldList'
import { setQuestionsToShow, defaultValues, setQuestionsToSend } from './utils'
import { createSurvey, CreateSurveyProps } from 'services/api'

export interface ISurveyFormInput {
  title: string
  identifier: SurveyIdentifierType
  description: string
  surveyQuestions: ISurveyQuestionProps[] | SurveyQuestionModel[]
  isEnable: boolean
}

type Props = {
  toggleModal: () => void
  updateData: () => void
}

const CreateSurveyModal = ({ toggleModal, updateData }: Props) => {
  const methods = useForm<ISurveyFormInput>({ defaultValues })

  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [previewContent, setPreviewContent] = useState<SurveyTemplateModel | null>(null)

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        refPreviewModal?.current.close()
        return
      }
      const result = setQuestionsToShow({
        question: methods.getValues('surveyQuestions'),
        questionsReused: []
      })
      setPreviewContent({
        id: 1,
        title: methods.getValues('title'),
        identifier: 'feedback',
        isEnable: true,
        surveyQuestions: result ?? [],
        description: methods.getValues('description')
      })
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const onSubmit: SubmitHandler<ISurveyFormInput> = async (
    data: ISurveyFormInput
  ): Promise<void> => {
    try {
      setIsLoading(true)
      const dto: CreateSurveyProps = {
        title: data.title,
        identifier: data.identifier,
        description: data.description,
        isEnable: data.isEnable,
        surveyQuestions: setQuestionsToSend(data.surveyQuestions)
      }

      const response = await createSurvey(dto)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      updateData()
    } catch (error: any) {
      setIsLoading(false)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao adicionar uma nova pesquisa')
    } finally {
      methods.reset()
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Nova pesquisa"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        handleOpenModal={togglePreviewModal}
        btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 'title',
              question: 'Digite o título da pesquisa:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
          <TextareaField
            attribute={{
              id: 'description',
              question: 'Descrição da pesquisa:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
          />

          {/* <SelectField
            attribute={{
              id: 'identifier',
              question: 'Selecione o destino ao qual estará direcionada a pesquisa:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            defaultValue={[identifierOptions[0]] as OptionSelect[]}
            options={identifierOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customSelectStyles}
          /> */}
          <SurveyFieldList<ISurveyFormInput> fieldName="surveyQuestions" />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal} $width="70%">
        {isOpenPreviewModal && previewContent && (
          <PreviewSurveyModal toggleModal={togglePreviewModal} survey={previewContent} />
        )}
      </ModalWrapper>
    </FormProvider>
  )
}

export default CreateSurveyModal
