import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../entities'
import { StarsRating } from 'components'

import { ErrorMessage, FormInputCPF } from './styles'

type Props = {
  attribute: AttributeModel
  scaleLimit?: number
}

const StarsField = ({ attribute, scaleLimit = 5 }: Props) => {
  const {
    control,
    formState: { errors }
  } = useFormContext()

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={{ required: attribute.required ?? false }}
      render={({ field: { onChange } }) => (
        <FormInputCPF>
          <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
          <StarsRating
            handleRating={(value) => {
              onChange(value)
            }}
            scale={scaleLimit}
          />
          {errors[attribute.id.toString()]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
        </FormInputCPF>
      )}
    />
  )
}

export default StarsField
