import axios from 'axios'

const url = process.env.REACT_APP_URL_FILE_MANAGER

const api = axios.create({
  baseURL: url
})

// type FileResponse = {
//   name: string // url
//   originalname: string // filename
// }
export async function getFileUrl (formData: any) {
  return await api.post('/api/file/private/', formData, {
    headers: {
      Authorization: process.env.REACT_APP_TOKEN_FILE_MANAGER,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export async function deleteFile (urls: any) {
  return await api.delete('/api/file/private/', {
    headers: {
      Authorization: process.env.REACT_APP_TOKEN_RECIVED_FILE_MANAGER
    },
    data: urls
  })
}

export async function getFileUrlPublic (formData: any) {
  return await api.post('/api/file/public/1', formData, {
    headers: {
      Authorization: process.env.REACT_APP_TOKEN_FILE_MANAGER,
      'Content-Type': 'multipart/form-data'
    }
  })
}

export async function deleteFilePublic (url: string) {
  return await api.delete(`${url}`, {
    headers: {
      Authorization: process.env.REACT_APP_TOKEN_FILE_MANAGER
    }
  })
}

export async function getMultiplePublicFile (fileList: File[]) {
  return await axios.all(
    fileList.map(async (file) => {
      const formData = new FormData()
      formData.append('files', file)
      return await getFileUrlPublic(formData)
    })
  )
}

export async function deleteMultiplePublicFile (urlList: string[]) {
  return await axios.all(
    urlList.map(async (url) => {
      return await deleteFilePublic(url)
    })
  )
}
