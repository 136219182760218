import { useNavigate } from 'react-router-dom'
import { ButtonGoBack } from 'components'
import { ContainerBreadcrumb } from './styles'

type Props = {
  titlePage?: string
  goToHome?: boolean
  goBackStep?: boolean
}

const Breadcrumb = ({ titlePage = '', goToHome = false, goBackStep = false }: Props) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    if (goToHome) {
      navigate('/')
      return
    }
    if (goBackStep) {
      navigate(-2)
      return
    }
    navigate(-1)
  }

  return (
    <ContainerBreadcrumb>
      <ButtonGoBack onClick={handleGoBack} />
    </ContainerBreadcrumb>
  )
}

export default Breadcrumb
