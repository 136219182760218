import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import AdminModal from 'components/Modal/ModalContent'
import { IPartialTicket, SurveyAnswerModel, SurveyAnswersModel } from '../../entities'
import { Spinner, SurveyInputsToRender } from 'components'
import { useGetActiveSurveyByIdentifier } from 'hooks'
import { saveSurveyAnswers } from 'services/api'

import { WrapperSurveyForm } from './styles'

type Props = {
  ticket: IPartialTicket
  toggleModal: () => void
  updateData: () => void
}

const TicketSurveyModal = ({ ticket, toggleModal, updateData }: Props) => {
  const methods = useForm()
  const { getSurvey, survey, isLoadingSurvey } = useGetActiveSurveyByIdentifier('feedback')
  const [isLoading, setIsLoading] = useState(false)

  const onSubmitForm = async (data: Record<string, string | number>) => {
    try {
      setIsLoading(true)
      if (!survey) {
        return
      }

      const answers: SurveyAnswersModel[] = []
      for (const [key, value] of Object.entries(data)) {
        answers.push({ answer: value, surveyQuestion: { id: parseInt(key) } })
      }

      const dto: SurveyAnswerModel = {
        userName: JSON.parse(localStorage.getItem('user') as string).userName ?? '',
        userLogin: JSON.parse(localStorage.getItem('user') as string).userLogin ?? '',
        userEmail: JSON.parse(localStorage.getItem('user') as string).userEmail ?? '',
        ticketId: ticket.ticketID,
        templateId: survey?.id,
        ticketHandle: ticket?.persistedId ?? '',
        surveyAnswers: answers
      }

      await saveSurveyAnswers(dto)
      setIsLoading(false)
      toggleModal()
      updateData()
      toast.success('😄 Obrigada pelo seu feedback!')
    } catch (error: any) {
      setIsLoading(false)
      toggleModal()
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao salvar a pesquisa ')
    }
  }

  useEffect(() => {
    getSurvey()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <FormProvider {...methods}>
      <AdminModal
        title={survey?.title ?? 'Pesquisa de satisfação'}
        handleCloseModal={toggleModal}
        handleSubmit={!survey ? null : methods.handleSubmit(onSubmitForm)}
        isLoading={isLoading}
        btnText="Enviar"
      >
        <WrapperSurveyForm>
          {isLoadingSurvey && <Spinner />}
          {!isLoadingSurvey && !survey && (
            <span className="survey_message_error">
              Não foi possível carregar os dados da pesquisa, por favor tente mais tarde!
            </span>
          )}
          {!isLoadingSurvey && survey && (
            <>
              {survey?.description && <p className="survey_description">{survey?.description}</p>}
              <div className="survey_form">
                <SurveyInputsToRender questions={survey.surveyQuestions} />
              </div>
            </>
          )}
        </WrapperSurveyForm>
      </AdminModal>
    </FormProvider>
  )
}

export default TicketSurveyModal
