import { ISurveyQuestionProps } from 'components/Fields/SurveyFields/SurveyFieldList'
import { SurveyQuestionModel } from '../../../entities'
import { formatOptions } from './formatOptions'
import { SurveyQuestionOptionsProps } from 'components/Fields/SurveyFields/SurveySelect'

type Props = {
  question: ISurveyQuestionProps[]
  questionsReused: SurveyQuestionModel[] | []
}

export const setQuestionsToShow = ({ question, questionsReused }: Props): SurveyQuestionModel[] => {
  const _questionsCreated = [...question]
  const list: SurveyQuestionModel[] = [...questionsReused]

  _questionsCreated.forEach(el => {
    let options: string[] | null = null
    let scaleLimit: number = 0
    if (el.options && el.options?.length > 0) {
      options = formatOptions(el.options as SurveyQuestionOptionsProps[])
    }
    if (el.scaleLimit && el.type === 'stars') {
      scaleLimit = parseInt(el.scaleLimit.toString())
    }
    list.push({
      ...el,
      id: crypto.randomUUID(),
      options,
      scaleLimit
    })
  })

  return list
}

export const setQuestionsToSend = (question: ISurveyQuestionProps[]) => {
  const list: Array<Omit<SurveyQuestionModel, 'id'>> = []

  question.forEach(el => {
    let options: string[] | null = null
    let scaleLimit: number = 0
    if (el.options && el.options?.length > 0) {
      options = formatOptions(el.options as SurveyQuestionOptionsProps[])
    }
    if (el.scaleLimit && el.type === 'stars') {
      scaleLimit = parseInt(el.scaleLimit.toString())
    }
    list.push({
      ...el,
      options,
      scaleLimit
    })
  })

  return list
}
