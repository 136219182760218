import { useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalContent } from 'components'
import { deleteMultiplePublicFile, getMultiplePublicFile } from 'services/fileManager'
import { OptionSelect } from 'components/Select/Select'
import { FormAssociatedModel } from '../../entities'
import { createArticle } from 'services/api'
import { EditorContentField, SelectField, TextField } from 'components/Fields'

import { ModalBodyForm } from 'components/Modal/styles'
import { customSelectStyles } from './utils'

interface IFormInput {
  formsAssociated: FormAssociatedModel[]
  content: string
  title: string
}

type Props = {
  toggleModal: () => void
  formOptions: OptionSelect[]
  getArticles: () => void
  isLoadingFormsAssociated: boolean
}

export type ImageProps = {
  fileName: string
  index: number
  base64: string
}

const AddArticleModal = ({
  toggleModal,
  formOptions,
  getArticles,
  isLoadingFormsAssociated
}: Props) => {
  const methods = useForm<IFormInput>()
  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState<ImageProps[]>([])
  const [files, setFiles] = useState<File[]>([])

  const replaceBase64ByUrl = (
    content: string,
    urlList: Array<{ name: string, url: string }>
  ): string => {
    let newValue = ''

    if (images.length > 0) {
      let auxNewValue = content
      images.forEach(({ fileName, base64 }) => {
        const newUrl = urlList.find((url: any) => url.name === fileName)
        if (newUrl) {
          newValue = auxNewValue
          newValue = newValue.replace(base64, newUrl?.url)
          auxNewValue = newValue
        }
      })
    }
    return newValue
  }

  const getUrlsImage = async (): Promise<Array<{ name: string, url: string }>> => {
    const urlList: Array<{ name: string, url: string }> = []
    if (files.length > 0) {
      const response = await getMultiplePublicFile(files)
      if (!response) throw new Error()
      response.forEach((file: any) =>
        urlList.push({ name: file.data[0].originalname, url: file.data[0].name })
      )
    }
    return urlList
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }
    setIsLoading(true)
    let urlList = []
    const list: string[] = []
    try {
      urlList = await getUrlsImage()
      urlList.forEach((el) => list.push(el.url))
      const newContent = replaceBase64ByUrl(data.content, urlList)
      const dto = {
        title: data.title,
        content: newContent || data.content,
        files: list,
        formsAssociated: data.formsAssociated
      }
      const response = await createArticle(dto)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      getArticles()
    } catch (error: any) {
      if (urlList.length > 0) {
        await deleteMultiplePublicFile(list)
      }
      setIsLoading(false)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao adicionar a FAQ')
    } finally {
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Nova FAQ"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <SelectField
            attribute={{
              // id: 0,
              id: 'formsAssociated',
              question: 'Qual formulário esta Faq estará relacionada?',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: 'Adicione o título...'
            }}
            options={formOptions}
            isLoadingData={isLoadingFormsAssociated}
            menuPlacement={'bottom'}
            customSelectStyles={customSelectStyles}
            isMulti={true}
          />

          <TextField
            attribute={{
              // id: 1,
              id: 'title',
              question: 'Adicione o título:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: 'Adicione o título...'
            }}
          />
          <EditorContentField
            fieldName="content"
            validations={{
              required: {
                value: true,
                message: '* Campo obrigatório'
              }
            }}
            images={images}
            setImages={setImages}
            files={files}
            setFiles={setFiles}
            label="Escreva o conteúdo da faq"
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default AddArticleModal
