import { ReactNode, useContext, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { AuthContext } from 'contexts/auth'
import { useWindowDimensions } from 'hooks'

import logoTJ from '../../../assets/logotransparent.webp'
import { Container, ContainerFooter, Header, Sidebar, WrapperContentSidebar } from './styles'

const links = [
  { href: '/admin/news', icon: 'fa-solid fa-bullhorn', label: 'Destaques' },
  { href: '/admin/faq', icon: 'fa-regular fa-circle-question', label: 'FAQs' },
  { href: '/admin/card', icon: 'fa-solid fa-box', label: 'Cards' },
  { href: '/admin/forms', icon: 'fa-regular fa-file-lines', label: 'Formulários' },
  { href: '/admin/questions', icon: 'fa-solid fa-file-circle-question', label: 'Perguntas' },
  { href: '/admin/survey', icon: 'fa-solid fa-comments', label: 'Pesquisas' },
  { href: '/admin', icon: 'fa-solid fa-user-tie', label: 'Usuários' },
  { href: '/', icon: 'fa-solid fa-house', label: 'Home' }
]

type Props = {
  children: ReactNode
  title: string
}
const AdmLayout = ({ children, title }: Props) => {
  const { logout } = useContext(AuthContext)
  const user = JSON.parse(localStorage.getItem('user') as string)
  const firstName = user?.userName?.split(' ')[0] ?? ''
  const navigate = useNavigate()
  if (user?.role !== 'ADMIN') {
    navigate('/')
  }
  const { width } = useWindowDimensions()
  useEffect(() => {
    document.title = title ?? ''
  }, [title])

  return (
    <Container>
      <Header>
        <div className="responsive_container">
          <div className="header-logo__info">
            <img src={logoTJ} alt="logo do TJ" />
            <span>Painel de administração</span>
          </div>
          <div className="header-info">
            <div className="header-info-icon">
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
            <div>
              <p>{`Olá, ${firstName as string}!`}</p>
              <p>Administrador</p>
            </div>
          </div>
        </div>
      </Header>
      <WrapperContentSidebar>
        <div className="responsive_container">
          <Sidebar>
            {links.map((link) => (
              <NavLink
                key={link.href}
                end
                to={link.href}
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                {width <= 768 ? (
                  <FontAwesomeIcon icon={link.icon as IconProp} />
                ) : (
                  <>
                    <FontAwesomeIcon icon={link.icon as IconProp} />
                    {link.label}
                  </>
                )}
              </NavLink>
            ))}
            <NavLink
              to="/login"
              className="link-bottom"
              onClick={() => {
                logout()
              }}
            >
              {width <= 768 ? (
                <FontAwesomeIcon icon={'fa-solid fa-arrow-right-from-bracket' as IconProp} />
              ) : (
                <>
                  <FontAwesomeIcon icon={'fa-solid fa-arrow-right-from-bracket' as IconProp} />
                  Sair
                </>
              )}
            </NavLink>
          </Sidebar>
          <div className="wrapper-content">{children}</div>
        </div>
      </WrapperContentSidebar>
      <ContainerFooter>
        <div className="responsive_container">
          <p>
            Poder Judiciário do Estado da Bahia - 2023. Todos os direitos reservados. Versão 3.0
          </p>
        </div>
      </ContainerFooter>
    </Container>
  )
}

export default AdmLayout
