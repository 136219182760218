import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getTicket } from '../services/connector'
import { ITicket, TicketModel } from '../entities'
import { AuthContext } from 'contexts/auth'

export const formatTicketData = (data: TicketModel) => {
  const categoriesFormatted = data.ca_category_name.split('.')
  const descriptionSplicedByQuestion = data.ca_description.split(/\n/g)
  const descriptionSplicedCleaned = descriptionSplicedByQuestion.filter(value => value !== '\r')
  const descriptionSplicedTrim = descriptionSplicedCleaned.map(value => value.trim())

  const dataFormatted: ITicket = {
    ticketID: data.ca_id || 'ID inválido, por favor, entre em contato conosco.',
    createdAt: data.ca_open_date || 'Sem data definida',
    title: data.ca_summary || 'Chamado sem título',
    // creator:
    //   data.user_profile === 'Usuário' ? `Chamado criado pelo usuário ${data.user_created_ticket_name}` : `Chamado criado por ${data.user_profile}`,
    status: data.ca_status || 'Status do chamado desconhecido',
    description: descriptionSplicedTrim || ['Chamado sem descrição'],
    group: data.group_name || 'Chamado sem categoria.',
    dueAt: data.ca_data_vencimento || 'Em processamento da data de vencimento.',
    latestFallowUp: (!data.latestFallowUp) ? 'Este chamado ainda não tem acompanhamento.' : data.latestFallowUp.toString(),
    requesterName: data.user_created_ticket_name || 'Solicitante é desconhecido',
    categories: categoriesFormatted || ['Sem categoria'],

    // type: '',
    followups: data.ca_followups
  }

  return dataFormatted
}

export const useGetTicketDetails = (ticketId: string | number, user = null) => {
  const [ticket, setTicket] = useState<ITicket | null>(null)
  const [isLoadingTicket, setLoadingTicket] = useState(false)
  const { logout } = useContext(AuthContext)

  const getTicketDetails = async (): Promise<void> => {
    setLoadingTicket(true)
    try {
      const userLogin = user ?? JSON.parse(localStorage.getItem('user') as string).userLogin

      if (!userLogin) {
        toast.error('Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente')
        setTimeout(() => {
          logout()
        }, 3000)
        return
      }

      const response = await getTicket(userLogin, ticketId)
      if (!response) {
        setTicket(null)
        throw new Error()
      }

      const ticketFormatted = formatTicketData(response)
      setTicket(ticketFormatted)
    } catch (error: any) {
      toast.error('Ops 😥, ocorreu um erro ao recuperar o chamado ')
    } finally {
      setLoadingTicket(false)
    }
  }

  useEffect(() => {
    getTicketDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    ticket,
    isLoadingTicket
  }
}
