import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'
import { btnSize } from 'components/Home/styles'

export const ContainerHome = styled.div`
  width: 100%;
  height: 100%;
  margin-top: -3.5rem;

  .home-info {
    display: grid;
    grid-template-rows: repeat(1, minmax(0, 1fr));
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    height: fit-content;
    width: 100%;

    margin-top: 1.5rem;
    padding: 0 ${btnSize.lg};
    margin-bottom: 1rem;
    gap: 1.5rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    margin-top: -3.25rem !important;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, 641)} {
    margin-top: -3rem !important;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    margin-top: -2.5rem;

    .home-info {
      padding: 0 ${btnSize.md};
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    margin-top: -3rem;
  }

  ${breakQueriesMaxMin(1320, SizeBreakpoint.xl)} {
    margin-top: -3.15rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    .home-info {
      display: grid;
      grid-template-rows: repeat(3, minmax(0, 1fr));
    }
  }

  @media (max-height: 768px) {
    margin-top: -3.65rem !important;
  }

  @media (min-height: 800px) and (max-height: 900px) and (min-width: 1366px) {
    .home-info {
      margin-top: 2.5rem;
    }
  }

  @media (min-height: 901px) and (max-height: 1199px) and (min-width: 1366px) {
    .home-info {
      margin-top: 3rem;
    }
  }

  @media (min-height: 1200px) and (min-width: 1366px) {
    margin-top: -4rem !important;
    .home-info {
      margin-top: 3.5rem;
    }
  }
`
