import { FileField, TextareaField } from 'components/Fields'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { updateStatusTicket } from 'services/connector'
import { deleteFile, getFileUrl } from 'services/fileManager'
import { Breadcrumb, Button, Layout } from '../../components'
import { UpdateTicketToSubmit } from '../../entities'

import { ContainerTicketForm, Form } from './styles'

export const UpdateTicket = () => {
  const methods = useForm()
  const navigate = useNavigate()
  const { ticketId } = useParams()
  const { state } = useLocation()
  const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin
  const [isLoading, setIsLoading] = useState(false)

  const getUrlFile = async (fileData: File) => {
    const formData = new FormData()
    formData.append('files', fileData)
    const response = await getFileUrl(formData)
    if (!response.data) {
      throw new Error()
    }
    return [
      {
        file_url: response.data[0].name,
        token: ''
      }
    ]
  }

  const onSubmitForm = async (data: Record<string, string | File>) => {
    setIsLoading(true)
    let attachmentsCa: Array<{ file_url: string, token: string }> = []
    if (data.file) {
      attachmentsCa = await getUrlFile(data.file as File)
    }
    try {
      const dto: UpdateTicketToSubmit = {
        tj_user_email: userLogin as string,
        description: data.description as string,
        attachments_ca: attachmentsCa
      }

      const response = await updateStatusTicket(dto, ticketId as string)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
      navigate(`/ticket/detail/${ticketId as string}`)
    } catch (error) {
      setIsLoading(false)
      if (attachmentsCa.length > 0) {
        const urlFile = [{ name: attachmentsCa[0]?.file_url }]
        await deleteFile(urlFile)
      }

      toast.error('Ops 😥, ocorreu um erro ao atualizar o chamado')
    }
  }
  return (
    <Layout title="Atualizar chamado">
      <ContainerTicketForm>
        <Breadcrumb titlePage="Atualizar chamado" />
        <div className="ticket-form-content">
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmitForm)}>
              <h2>Preencha os campos abaixo </h2>
              <div className="form-inputs">
                <TextareaField
                  attribute={{
                    id: 'description',
                    question: 'Qual seu comentário para o chamado?',
                    optionsSelectElement: [],
                    required: true,
                    isHiddenToVip: false,
                    placeholder: ''
                  }}
                />
                {state?.status.toUpperCase() !== 'RESOLVIDO' && (
                  <FileField
                    attribute={{
                      id: 'file',
                      question: 'Você deseja anexar arquivo?',
                      optionsSelectElement: [],
                      required: false,
                      isHiddenToVip: false,
                      placeholder: ''
                    }}
                  />
                )}
              </div>
              <div className="form-button">
                <Button type="submit" isLoading={isLoading} text="Enviar" />
              </div>
            </Form>
          </FormProvider>
        </div>
      </ContainerTicketForm>
    </Layout>
  )
}
