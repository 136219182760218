import styled from 'styled-components'
import image_bgtop from '../../assets/background-tj.png'

export const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f3f7 url(${image_bgtop}) 0% 0% no-repeat padding-box;
  background-size: 100% 40%;
`
export const ErrorMessage = styled.span`
  width: 100% !important;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.red._500};
  font-size: 0.75rem;
  font-weight: 500;
`

export const WrapperLogin = styled.div`
  width: 24rem;
  height: fit-content;
  min-height: 27rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1.25rem;
  padding: 1.5rem 4rem;
  gap: 1rem;

  .login-logo {
    width: 7rem;
    margin-bottom: 1rem;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    .form-field {
      svg {
        font-size: 0.875rem;
        margin: 0.65em 0.5em 0.5em 0.65em;
        color: ${({ theme }) => theme.colors.blue._500};
        position: absolute;
      }

      .user-icon {
        width: 0.875rem;
        height: 0.875rem;
      }

      input {
        width: 100%;
        height: 2.2rem;
        font: italic normal normal 14px/15px Montserrat;
        background: ${({ theme }) => theme.colors.white};
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.blue._500};
        border-left: 4px solid ${({ theme }) => theme.colors.blue._500};
        padding-left: 2rem;
        &::placeholder {
          font: italic normal normal 12px/15px Montserrat;
          color: ${({ theme }) => theme.colors.gray._500};
        }

        &:focus {
          outline: 1px solid ${({ theme }) => theme.colors.blue._500};
        }
      }
    }
  }

  .login-error {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Monteserrat;
    color: ${({ theme }) => theme.colors.red._500};
  }

  .attorneyOrDelegate {
    display: flex;
    width: 100%;
    font: normal normal normal 11px Montserrat;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-top: 1.5rem;
    height: 2.2rem;
    font-weight: bold;
    border: 1px solid ${({ theme }) => theme.colors.blue._800};
    border-radius: 8px;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.blue._800} 0% 0% no-repeat padding-box;
    color: ${({ theme }) => theme.colors.white};
    transition: 0.2s;

    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.blue._800};
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    bottom: 0;
    font-size: 0.75rem;
    font-weight: bold;

    span:first-child {
      color: ${({ theme }) => theme.colors.blue._800};
    }
  }
`
