import { useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../entities'

import { ErrorMessage, FormInputTextarea } from './styles'

type Props = {
  attribute: AttributeModel
}

const TextareaField = ({ attribute }: Props) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  const isRequired = attribute.required ?? false
  return (
    <FormInputTextarea>
      <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
      <textarea {...register(attribute.id.toString(), { required: isRequired })} />
      {errors[attribute.id.toString()]?.type === 'required' && (
        <ErrorMessage>* Campo obrigatório</ErrorMessage>
      )}
    </FormInputTextarea>
  )
}

export default TextareaField
