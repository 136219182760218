import { useParams, Link, useLocation } from 'react-router-dom'
import { ContainerLoading, Layout } from '../../components'
import { useGetTicketDetails } from '../../hooks'

import successImg from '../../assets/success.svg'
import { ContainerSuccess } from './styles'

export function Success () {
  const { ticketId } = useParams()
  const { state } = useLocation()
  const { isLoadingTicket, ticket } = useGetTicketDetails(ticketId as string, state?.user)
  return (
    <Layout title={isLoadingTicket ? 'Carregando...' : 'Chamado criado com sucesso'}>
      <ContainerSuccess>
        {isLoadingTicket ? (
          <ContainerLoading />
        ) : (
          <div className="success-content">
            <img src={successImg} alt="success" />
            <h2>Seu chamado foi registrado!</h2>
            <div className="success-content-info">
              <span>
                Para consultar o andamento do chamado utilize o ID abaixo. Você pode consultar o
                chamado através do nosso chatbot ou através da Central de serviços de TI.
              </span>
              <h4>Grupo Solucionador: {ticket?.group.split('_')[0]}</h4>
              <h4>Vencimento: {ticket?.dueAt}</h4>
              <h4>O número do seu chamado registrado é: {ticketId}</h4>
              <div className="success-btn">
                <Link to="/">
                  <button>Voltar para a tela inicial</button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </ContainerSuccess>
    </Layout>
  )
}
