import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const Container = styled.div<{ $isHomePage?: boolean, $isOverflowY?: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray._200};
  overflow-x: hidden !important;

  ${breakQueriesMaxMin(SizeBreakpoint.md, 641)} {
    height: ${(props) => (props.$isHomePage ? '100%' : '100vh')};
  }
  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    height: ${(props) =>
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      props.$isHomePage || props.$isOverflowY ? '100%' : '100vh'};
  }

`
export const ContainerFooter = styled.footer`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.blue._800} 0% 0% no-repeat padding-box;
  padding: 0.5rem 12px;

  p {
    text-align: center;
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: ${({ theme }) => theme.colors.white};
    opacity: 1;
  }
`
