import { useState } from 'react'
import { WrapperStart } from './styles'

type Props = {
  handleRating: (value: number) => void
  scale: number
}

const StarRating = ({ handleRating, scale }: Props) => {
  const [rating, setRating] = useState<number | null>(null)
  const [hover, setHover] = useState<number | null>(null)

  return (
    <WrapperStart>
      {[...Array(scale)].map((star, index: number) => {
        const currentRating = index + 1

        return (
          <label key={index}>
            <input
              // key={star}
              type="radio"
              name="rating"
              value={currentRating}
              onChange={() => {
                setRating(currentRating)
                handleRating(currentRating)
              }}
            />
            <span
              className="star"
              style={{
                color:
                  currentRating <= ((hover as number) || (rating as unknown as number)) ? '#ffc107' : '#e4e5e9'
              }}
              onMouseEnter={() => {
                setHover(currentRating)
              }}
              onMouseLeave={() => {
                setHover(null)
              }}
            >
              &#9733;
            </span>
          </label>
        )
      })}
    </WrapperStart>
  )
}

export default StarRating
