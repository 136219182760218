import styled from 'styled-components'

export const TableCard = styled.table`
  width: 100%;
  border-collapse: collapse;
  box-sizing: border-box;

  thead {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray._800};
    text-transform: uppercase;

    tr {
      width: 100%;
      height: 4rem;
    }

    th {
      text-align: center;
      border-bottom: 2px solid ${({ theme }) => theme.colors.gray._200};
      height: 4rem;
      max-height: 4rem;
      min-height: 4rem;
    }
    .column-title {
      text-align: left;
    }
  }

  tbody {
    color: ${({ theme }) => theme.colors.gray._600};

    tr {
      width: 100%;
      height: 4rem;

      td {
        border-bottom: 2px solid ${({ theme }) => theme.colors.gray._200};
        height: 4rem;
        max-height: 4rem;
        min-height: 4rem;
      }

      .column-icon {
        justify-content: left;
        width: 10%;
        height: 100%;
        height: 4rem;
        .wrapper-icon {
          display: flex;
          align-items: center;
          gap: 1rem;
          height: 100%;
          margin-right: 1rem;
        }
      }

      .column-title {
        text-align: left;
        font-size: 0.95rem;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.blue._800};
        button {
          text-align: left;
          border: none;
          background-color: transparent;
          font-size: 0.95rem;
          font-weight: bold;
          color: ${({ theme }) => theme.colors.blue._800};
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .column-action {
        text-align: center;
        width: 15%;
        button {
          border: none;
          background-color: ${({ theme }) => theme.colors.white};
          margin-left: 0.75rem;
          cursor: pointer;

          svg {
            width: 1.25rem;
            height: 1.25rem;
            color: ${({ theme }) => theme.colors.gray._600};
          }
        }

        .action-edit {
          &:hover {
            svg {
              color: ${({ theme }) => theme.colors.green._600};
            }
          }
        }

        .action-remove {
          &:hover {
            svg {
              color: ${({ theme }) => theme.colors.red._700};
            }
          }
        }

        .action-move {
          &:hover {
            cursor: grab;
            svg {
              color: ${({ theme }) => theme.colors.blue._600};
            }
          }
          &:active {
            cursor: grabbing;
          }
        }

        @media (max-width: 930px) {
          width: 20%;
        }

        @media (max-width: 660px) {
          width: 13%;
          padding: 0.5rem 0;
          button {
            margin-top: 0.25rem;
            margin-bottom: 0.25rem;
          }
        }
      }

      .column-active {
        text-align: center;
        width: 10%;
        input {
          height: 1rem;
          width: 1rem;
          max-width: 1rem;
          min-width: 1rem;
        }
      }
    }
  }
`
