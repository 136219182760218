import { IAttributeProps } from 'components/Fields/AttributeFields/AttributeFieldList'
import { AttOptionsProps } from 'components/Fields/AttributeFields/Select'

export const removeOptionsId = (list: IAttributeProps[]) => {
  const _attributes: IAttributeProps[] = []

  list.forEach((att) => {
    const _options: AttOptionsProps[] = []
    if (att.optionsSelectElement.length > 0) {
      att.optionsSelectElement.forEach((opt) => {
        _options.push({
          title: opt.title,
          value: opt.value,
          attributes: opt.attributes,
          weight: opt.weight,
          propertyId: opt.propertyId,
          categoryId: opt.categoryId,
          type: opt.type,
          group: opt.group,
          categoryName: opt.categoryName,
          openingStatus: opt.openingStatus
        })
      })
      _attributes.push({ ...att, optionsSelectElement: _options })
    } else {
      _attributes.push({ ...att })
    }
  })

  return _attributes
}
