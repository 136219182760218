import { ButtonHTMLAttributes } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-regular-svg-icons'
import { BtnBell } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLElement> { }

const BtnNotificationBell = (Properties: Props) => {
  const { ...props } = Properties
  return (
    <BtnBell title="Notificação" {...props}>
      <span className="label" />
      <FontAwesomeIcon icon={faBell} />
    </BtnBell>
  )
}

export default BtnNotificationBell
