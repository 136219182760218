import { Breadcrumb, Layout, Notification } from 'components'
import { ContainerNotification } from './styles'

export const NotificationPage = () => {
  return (
    <Layout title="Notificação">
      <ContainerNotification>
        <Breadcrumb titlePage="Notificação" goToHome={true} />
        <div className="content">
          <Notification message="No momento não existe nenhum formulário ou card associado a esta página." />
        </div>
      </ContainerNotification>
    </Layout>
  )
}
