import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { AdmLayout, Button, ButtonGoBack, CategoryInfoButton, CategoryModal, PreviewFormModal } from 'components'
import { SelectField, TextField } from 'components/Fields'
import { useGetAttributes, useGetCardList, useSetExtraQuestions } from 'hooks'
import { customSelectStyles } from 'components/ArticlesAdmin/utils'
import { OptionSelect } from 'components/Select/Select'
import { CreateFormProps, createForm } from 'services/api'
import { CategoryStatus, CategoryType, FormI } from '../../../entities'
import { buildCardOptions, removeOptionsId, setAttributeToShow } from 'components/FormAdmin/utils'

import {
  ModalWrapper,
  TitlePage,
  WrapperAddForm,
  WrapperBtnGoBack,
  WrapperContent
} from 'pages/Admin/styles'
import AttributeFieldList, {
  IAttributeProps
} from 'components/Fields/AttributeFields/AttributeFieldList'

export interface IFormInput {
  title: string
  associatedCard: any
  attributes: IAttributeProps[] | []
  attReused: any[] | []

  categoryId: string | null
  type: CategoryType | null
  group: string | null
  categoryName: string | null
  openingStatus: CategoryStatus | null

}

export const AddForm = () => {
  const defaultValues: IFormInput = {
    title: '',
    attReused: [],
    associatedCard: '',
    attributes: [
      {
        question: '',
        optionsSelectElement: [],
        required: true,
        isHiddenToVip: false,
        type: 'text',
        positionOrder: 1,
        isVisibleToDescription: true
      }
    ],
    categoryId: null,
    type: null,
    group: null,
    categoryName: null,
    openingStatus: null
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const {
    optionListSelected,
    setOptionListSelected,
    handleCheckboxExtraQuestions,
    handleSelectExtraQuestions
  } = useSetExtraQuestions({ methods })
  const { isLoadingAttributes, attributeOptions } = useGetAttributes()
  const { isLoadingCardList, cardList } = useGetCardList()
  const navigate = useNavigate()

  const refCategoryModal = useRef<HTMLDialogElement>(null)
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)

  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [previewContent, setPreviewContent] = useState<FormI>({} as FormI)
  const [cardOptions, setCardOptions] = useState<OptionSelect[]>([])

  const toggleCategoryModal = () => {
    if (refCategoryModal.current) {
      if (isOpenCategoryModal) {
        setIsOpenCategoryModal(false)
        refCategoryModal?.current.close()
        return
      }
      setIsOpenCategoryModal(true)
      refCategoryModal?.current.showModal()
    }
  }

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        setOptionListSelected([])
        refPreviewModal?.current.close()
        return
      }

      const result = setAttributeToShow({
        attrsCreated: methods.getValues('attributes'),
        attrsReused: methods.getValues('attReused')
      })

      setPreviewContent({
        id: 1,
        title: methods.getValues('title'),
        isEnable: true,
        category: {
          categoryId: methods.getValues('categoryId') as string,
          type: methods.getValues('type') as CategoryType,
          group: methods.getValues('group') as string,
          name: methods.getValues('categoryName') as string,
          status: methods.getValues('openingStatus') as CategoryStatus
        },
        attributes: result ?? [],
        color: '',
        parentName: '',
        articles: [],
        parentCard: ''
      })
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      const _attributes = removeOptionsId(data.attributes)
      const dto: CreateFormProps = {
        title: data.title,
        isEnable: true,
        associatedCard: data.associatedCard || null,
        attributes: [...data.attReused, ..._attributes],
        categoryId: data?.categoryId ?? null,
        type: data?.type ?? null,
        group: data?.group ?? null,
        categoryName: data?.categoryName ?? null,
        openingStatus: data?.openingStatus ?? null
      }

      const response = await createForm(dto)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao adicionar um novo formulário')
    } finally {
      methods.clearErrors()
      methods.reset()
      navigate('/admin/forms')
    }
  }

  useEffect(() => {
    if (!isLoadingCardList && cardList.length > 0) {
      const result = buildCardOptions(cardList)
      setCardOptions(result)
    }
  }, [isLoadingCardList, cardList])

  return (
    <AdmLayout title="Novo formulário">
      <WrapperBtnGoBack>
        <ButtonGoBack
          onClick={() => {
            navigate('/admin/forms')
          }}
          className="btn-go-back"
        />
      </WrapperBtnGoBack>
      <WrapperContent>
        <TitlePage>Novo formulário</TitlePage>

        <FormProvider {...methods}>
          <WrapperAddForm onSubmit={methods.handleSubmit(onSubmit)}>
            <TextField
              attribute={{
                id: 'title',
                question: 'Digite o título do formulário:',
                optionsSelectElement: [],
                required: true,
                isHiddenToVip: false
              }}
            />
            <CategoryInfoButton
              categorySelected={{
                categoryId: methods.getValues('categoryId') as string,
                type: methods.getValues('type') as CategoryType,
                group: methods.getValues('group') as string,
                name: methods.getValues('categoryName') as string,
                status: methods.getValues('openingStatus') as CategoryStatus
              }}
              toggleCategoryModal={toggleCategoryModal}
            />

            <SelectField
              attribute={{
                id: 'associatedCard',
                question: 'Selecione o card ao qual estará associado o formulário:',
                optionsSelectElement: [],
                required: false,
                isHiddenToVip: false
              }}
              options={cardOptions}
              menuPlacement={'bottom'}
              customSelectStyles={customSelectStyles}
              isLoadingData={isLoadingCardList}
            />

            <SelectField
              attribute={{
                id: 'attReused',
                question: 'Selecione as perguntas que deseja reaproveitar:',
                optionsSelectElement: [],
                required: false,
                isHiddenToVip: false
              }}
              options={attributeOptions}
              menuPlacement={'bottom'}
              customSelectStyles={customSelectStyles}
              isLoadingData={isLoadingAttributes}
              isMulti={true}
            />

            <AttributeFieldList<IFormInput>
              attributeFieldName="attributes"
              firstLevel={true} // TO AVOID INFINITY CYCLE OF SELECT <-> SELECT
            />
            <div className="form-button">
              <Button type="submit" isLoading={isLoading} text="Salvar" color="#008000" />
              <Button
                type="button"
                isLoading={false}
                text="Visualizar"
                onClick={togglePreviewModal}
              />
            </div>
          </WrapperAddForm>
          <ModalWrapper ref={refPreviewModal}>
            {isOpenPreviewModal && (
              <PreviewFormModal
                toggleModal={togglePreviewModal}
                attributes={previewContent.attributes}
                optionListSelected={optionListSelected}
                handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
                handleSelectExtraQuestions={handleSelectExtraQuestions}
              />
            )}
          </ModalWrapper>
          <ModalWrapper ref={refCategoryModal} $width="70%">
            {isOpenCategoryModal && <CategoryModal toggleModal={toggleCategoryModal} />}
          </ModalWrapper>
        </FormProvider>
      </WrapperContent>
    </AdmLayout>
  )
}
