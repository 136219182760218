import { useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { AttributeModel } from '../../entities'
import { ModalContent } from 'components'
import AttributeField from '../Fields/AttributeFields/AttributeField'
import PreviewFormModal from 'components/FormAdmin/PreviewFormModal'
import { useSetExtraQuestions } from 'hooks'
import { IAttributeProps } from 'components/Fields/AttributeFields/AttributeFieldList'
import { createAttribute } from 'services/api'
import { setAttributeToShow } from 'components/FormAdmin/utils'

import { ModalWrapper } from 'pages/Admin/styles'
import { ModalBodyForm } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
}

const AddAttributeModal = ({ toggleModal, updateData }: Props) => {
  const defaultValues: IAttributeProps = {
    question: '',
    optionsSelectElement: [],
    required: true,
    isHiddenToVip: false,
    type: 'text',
    positionOrder: 1,
    isVisibleToDescription: true,
    name: crypto.randomUUID() // REMOVE
  }

  const methods = useForm<IAttributeProps>({ defaultValues })

  const {
    optionListSelected,
    setOptionListSelected,
    handleCheckboxExtraQuestions,
    handleSelectExtraQuestions
  } = useSetExtraQuestions({ methods })

  const refPreviewModal = useRef<HTMLDialogElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [previewContent, setPreviewContent] = useState<AttributeModel[] | null>(null)

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        setOptionListSelected([])
        refPreviewModal?.current.close()
        return
      }
      const result = setAttributeToShow({
        attrsCreated: [{ ...methods.getValues() }],
        attrsReused: []
      })

      setPreviewContent(result ?? [])
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const onSubmit: SubmitHandler<IAttributeProps> = async (data: IAttributeProps): Promise<void> => {
    try {
      setIsLoading(true)
      const response = await createAttribute(data)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao criar a pergunta')
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Criar nova pergunta"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        handleOpenModal={togglePreviewModal}
        btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <AttributeField<IAttributeProps>
            attribute={methods.getValues()}
            question={'question'}
            type={'type'}
            positionOrder="positionOrder"
            isHiddenToVip="isHiddenToVip"
            isVisibleToDescription="isVisibleToDescription"
            required="required"
            isMoved={false} // HIDE MOVE BOTTOM
            optionsSelectElementFieldName={'optionsSelectElement'}
            firstLevel={true} // TO AVOID INFINITY CYCLE OF SELECT <-> SELECT
            // propertyId="propertyId"
          />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal}>
        {isOpenPreviewModal && previewContent && (
          <PreviewFormModal
            title="Visualização da pergunta"
            toggleModal={togglePreviewModal}
            attributes={previewContent}
            optionListSelected={optionListSelected}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
          />
        )}
      </ModalWrapper>
    </FormProvider>
  )
}

export default AddAttributeModal
