import { CategoryStatus, CategoryType, FormI } from '../../entities'
import { useGetAttributes, useGetCardList, useSetExtraQuestions } from 'hooks'
import { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { OptionSelect } from 'components/Select/Select'
import {
  buildCardOptions,
  findAttributeOptions,
  removeOptionsId,
  setAttributeToShow
} from './utils'
import { CreateFormProps, editForm } from 'services/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ModalWrapper, WrapperAddForm } from 'pages/Admin/styles'
import { AttributeFieldList, SelectField, TextField } from 'components/Fields'
import { customSelectStyles } from 'components/ArticlesAdmin/utils'
import PreviewFormModal from './PreviewFormModal'
import { CategoryModal, Button, CategoryInfoButton } from 'components'
import { IFormInput } from 'pages'

type Props = {
  form: FormI
}

const EditFormContent = ({ form }: Props) => {
  const { isLoadingAttributes, attributeOptions, attributeOptionsAll } = useGetAttributes()
  const { isLoadingCardList, cardList } = useGetCardList()
  const defaultValues: IFormInput = {
    title: form?.title ?? '',
    attReused: form?.attributes ?? [],
    associatedCard: form.associatedCard,
    attributes: [],
    categoryId: form.category?.categoryId ?? null,
    type: form.category?.type ?? null,
    group: form.category?.group ?? null,
    categoryName: form.category?.name ?? null,
    openingStatus: form.category?.status ?? null
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const navigate = useNavigate()

  const {
    optionListSelected,
    handleCheckboxExtraQuestions,
    handleSelectExtraQuestions,
    setOptionListSelected
  } = useSetExtraQuestions({ methods })

  const refCategoryModal = useRef<HTMLDialogElement>(null)
  const [isOpenCategoryModal, setIsOpenCategoryModal] = useState(false)

  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [previewContent, setPreviewContent] = useState<FormI>({} as FormI)

  const [isLoading, setIsLoading] = useState(false)
  const [cardOptions, setCardOptions] = useState<OptionSelect[]>([])

  const toggleCategoryModal = () => {
    if (refCategoryModal.current) {
      if (isOpenCategoryModal) {
        setIsOpenCategoryModal(false)
        refCategoryModal?.current.close()
        return
      }
      setIsOpenCategoryModal(true)
      refCategoryModal?.current.showModal()
    }
  }

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        setOptionListSelected([])
        refPreviewModal?.current.close()
        return
      }

      const result = setAttributeToShow({
        attrsCreated: methods.getValues('attributes'),
        attrsReused: methods.getValues('attReused')
      })

      setPreviewContent({
        id: 1,
        title: methods.getValues('title'),
        isEnable: true,
        category: {
          categoryId: methods.getValues('categoryId') as string,
          type: methods.getValues('type') as CategoryType,
          group: methods.getValues('group') as string,
          name: methods.getValues('categoryName') as string,
          status: methods.getValues('openingStatus') as CategoryStatus
        },
        attributes: result ?? [],
        color: '',
        parentName: '',
        articles: [],
        parentCard: ''
      })
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }
  const checkDataChanges = (dto: CreateFormProps): boolean => {
    const originalData = {
      title: form.title,
      isEnable: form.isEnable,
      associatedCard: form.associatedCard,
      attributes: form.attributes,
      categoryId: form?.category?.categoryId,
      type: form?.category?.type,
      group: form?.category?.group,
      categoryName: form?.category?.name,
      openingStatus: form?.category?.status
    }

    return JSON.stringify(originalData) === JSON.stringify(dto)
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      const _attributes = removeOptionsId(data.attributes)
      const dto: CreateFormProps = {
        title: data.title,
        isEnable: form?.isEnable,
        associatedCard: data.associatedCard || null,
        attributes: [...data.attReused, ..._attributes],
        categoryId: data?.categoryId ?? null,
        type: data?.type ?? null,
        group: data?.group ?? null,
        categoryName: data?.categoryName ?? null,
        openingStatus: data?.openingStatus ?? null
      }

      const isEqual = checkDataChanges(dto)
      if (isEqual) {
        toast.warn('Nada a ser editado!')
        return
      }

      const response = await editForm(form?.id as string, dto)
      if (!response) {
        throw new Error()
      }
      setIsLoading(false)
    } catch (error: any) {
      setIsLoading(false)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao adicionar um novo formulário')
    } finally {
      methods.reset()
      navigate('/admin/forms')
    }
  }

  useEffect(() => {
    if (!isLoadingCardList && cardList.length > 0) {
      const result = buildCardOptions(cardList)
      setCardOptions(result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCardList, cardList])
  return (
    <FormProvider {...methods}>
      <WrapperAddForm onSubmit={methods.handleSubmit(onSubmit)}>
        <TextField
          attribute={{
            id: 'title',
            question: 'Digite o título do formulário:',
            optionsSelectElement: [],
            required: true,
            isHiddenToVip: false
          }}
        />
        <CategoryInfoButton
          categorySelected={{
            categoryId: methods.getValues('categoryId') as string,
            type: methods.getValues('type') as CategoryType,
            group: methods.getValues('group') as string,
            name: methods.getValues('categoryName') as string,
            status: methods.getValues('openingStatus') as CategoryStatus
          }}
          toggleCategoryModal={toggleCategoryModal}
        />

        <SelectField
          attribute={{
            id: 'associatedCard',
            question: 'Selecione o card ao qual estará associado o formulário:',
            optionsSelectElement: [],
            required: false,
            isHiddenToVip: false
          }}
          options={cardOptions}
          menuPlacement={'bottom'}
          customSelectStyles={customSelectStyles}
          isLoadingData={isLoadingCardList}
          defaultValue={
            form?.associatedCard ? [
              {
                label: form?.ancestorsCardTreeName ?? '',
                value: {
                  id: form?.associatedCard?.id as number,
                  title: form?.associatedCard?.name ?? ''
                }
              }
            ] : undefined
          }
        />

        <SelectField
          attribute={{
            id: 'attReused',
            question: 'Selecione as perguntas deseja reaproveitar/remover:',
            optionsSelectElement: [],
            required: false,
            isHiddenToVip: false
          }}
          options={attributeOptions}
          menuPlacement={'bottom'}
          customSelectStyles={customSelectStyles}
          isLoadingData={isLoadingAttributes}
          isMulti={true}
          defaultValue={findAttributeOptions(form.attributes, attributeOptionsAll)}
        />

        <AttributeFieldList<IFormInput>
          attributeFieldName="attributes"
          firstLevel={true} // TO AVOID INFINITY CYCLE OF SELECT <-> SELECT
        />
        <div className="form-button">
          <Button type="submit" isLoading={isLoading} text="Salvar" color="#008000" />
          <Button type="button" isLoading={false} text="Visualizar" onClick={togglePreviewModal} />
        </div>
      </WrapperAddForm>

      <ModalWrapper ref={refPreviewModal}>
        {isOpenPreviewModal && (
          <PreviewFormModal
            toggleModal={togglePreviewModal}
            attributes={previewContent.attributes}
            optionListSelected={optionListSelected}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refCategoryModal} $width="70%">
        {isOpenCategoryModal && <CategoryModal toggleModal={toggleCategoryModal} />}
      </ModalWrapper>
    </FormProvider>
  )
}

export default EditFormContent
