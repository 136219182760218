import { useEffect } from 'react'

export const useAllTalk = () => {
  useEffect(() => {
    setTimeout(() => {
      const scriptAllTalk = document.createElement('script')

      scriptAllTalk.innerHTML =
        "(new AllTalk).authenticate('62266f89822a69002ce768de').createButton(['#f2f3f7'])"
      // Substitua pelo URL do script que você deseja inserir
      document.body.appendChild(scriptAllTalk)
      return () => {
        // Função de limpeza que é executada quando o componente é desmontado
        document.body.removeChild(scriptAllTalk)
      }
    }, 1000)
  }, [])
}

export const useScript = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src =
      'http://web-front-chatbots-platform.stg.tjba.jus.br/public/js/solutis_all_talk.js'
    script.async = true

    // Substitua pelo URL do script que você deseja inserir
    document.body.appendChild(script)
    return () => {
      // Função de limpeza que é executada quando o componente é desmontado
      document.body.removeChild(script)
    }
  }, [])
}

export const useShowIframeButton = () => {
  useEffect(() => {
    setTimeout(() => {
      // selected a class the iframe and button
      const iframeChatBot = document.querySelector('.solutis-alltalk-iframe')
      const buttonChatBot = document.querySelector('.solutis-alltalk-button')

      // remove and add a class the iframe and button to hidden or show
      iframeChatBot.classList.remove('solutis-alltalk-hidden')
      iframeChatBot.classList.add('animated')
      buttonChatBot.classList.add('solutis-alltalk-hidden')
    }, 2100)
  })
}
