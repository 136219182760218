import { ChangeEvent, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons'
import { AttributeModel } from '../../entities'

import { ErrorMessage, FormFileField } from './styles'

type Props = {
  attribute: AttributeModel
}

const FileField = ({ attribute }: Props) => {
  const {
    control,
    setValue,
    clearErrors,
    setError,
    formState: { errors }
  } = useFormContext()
  const [fileName, setFileName] = useState('')
  const isRequired = attribute.required ?? false

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    clearErrors(attribute.id.toString())
    if (!e.target.files) return
    if (e.target.files[0]) {
      // const defaultFormats = ['jpg', 'doc', 'pdf', 'png', 'jpeg', 'docx', 'xls', 'xlsx', 'xlsm', 'sheet']
      const defaultFormats = ['jpg', 'doc', 'pdf', 'png', 'jpeg', 'docx', 'mp4', 'MP4']
      console.log(e?.target?.files[0].name)

      const isValidFormat = defaultFormats.includes(e?.target?.files[0].name?.split('.')[1])

      if (!isValidFormat) {
        setError(attribute.id.toString(), { type: 'error', message: '* Formato inválido' })
        setFileName('')
        setValue(attribute.id.toString(), null)
        return
      }
      setFileName(e.target.files[0].name)
      setValue(attribute.id.toString(), e.target.files[0])
    }
  }

  const fileValidation = (isRequired: boolean) => {
    const file = {
      required: {
        value: isRequired,
        message: '* Campo obrigatório'
      }
    }
    return file
  }

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={fileValidation(isRequired)}
      render={() => (
        <FormFileField>
          <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
          <div className="form-file">
            <label className="form-file-input">
              <FontAwesomeIcon icon={faArrowUpFromBracket} />
              <span>Carregar arquivo</span>
              <input type="file" name={attribute.id.toString()} onChange={handleUpload} />
            </label>
            {fileName && <span className="form-file-name">{fileName}</span>}
          </div>

          {errors[attribute.id.toString()] && (
            <ErrorMessage>{errors[attribute.id.toString()]?.message?.toString()}</ErrorMessage>
          )}
        </FormFileField>
      )}
    />
  )
}

export default FileField
