import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ColorPicker } from '..'
import { ErrorMessage, FormColorField } from './styles'
// import { ErrorMessage, ModalBodyInput } from '../CardAdmin/Fields/styles'

type Props = {
  name: string
  isRequired: boolean
  defaultValue?: string
}

const ColorField = ({ name, isRequired, defaultValue = '#267fd7' }: Props) => {
  const {
    control,
    formState: { errors },
    setValue
  } = useFormContext()
  const [color, setColor] = useState(defaultValue)

  const handleChangeColor = (value: string) => {
    setColor(value)
    return value
  }

  useEffect(() => {
    setValue(name, color)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: isRequired }}
      render={({ field: { onChange } }) => (
        <FormColorField>
          <label htmlFor={name}>Selecione a cor:</label>
          <ColorPicker
            color={color}
            onChange={(value) => { onChange(handleChangeColor(value)) }}
          />
          {errors.login?.type === 'required' && <ErrorMessage>* Campo obrigatório</ErrorMessage>}
        </FormColorField>
      )}
    />
  )
}

export default ColorField
