import { KeyboardEvent, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { MultiValue, SingleValue } from 'react-select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { Breadcrumb, BtnSurveyTicket, Pagination, Select, TicketSurveyModal } from '..'
import {
  GetTicketListProps,
  useGetColorFromTicketStatus,
  useGetTicketSurveysAnswered
} from '../../hooks'
import { IPartialTicket } from '../../entities'
import { OptionSelect } from 'components/Select/Select'
import { styles, options } from './utils'

import { ContainerTicketsContent, ContainerTicketsHeading } from './styles'
import { ModalWrapper } from 'pages/Admin/styles'

type Props = {
  ticketList: IPartialTicket[]
  setTicketById: (value: null) => void
  handleSearch: (value: string) => void
  getTicketList: ({ limit, page, status }: GetTicketListProps) => Promise<void>
  pagination: {
    prev: boolean
    next: boolean
    page: number
  }
  ticketStatus: string
  setTicketStatus: (value: string) => void
}

const TicketListAll = ({
  ticketList,
  handleSearch,
  setTicketById,
  getTicketList,
  pagination,
  ticketStatus,
  setTicketStatus
}: Props) => {
  const refSurveyModal = useRef<HTMLDialogElement>(null)
  const { getColor } = useGetColorFromTicketStatus()
  const { getSurveyAnsweredList, surveysAnswered } = useGetTicketSurveysAnswered()

  const [ticketId, setTicketId] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [ticketSelected, setTicketSelected] = useState<IPartialTicket | null>(null)

  const toggleSurveyModal = (ticket: IPartialTicket | null = null) => {
    if (refSurveyModal.current) {
      if (isOpenModal) {
        setTicketSelected(null)
        setIsOpenModal(false)
        refSurveyModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setTicketSelected(ticket)
      setIsOpenModal(true)
      refSurveyModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const handleSelect = (e: SingleValue<OptionSelect> | MultiValue<OptionSelect>) => {
    const value = (e as SingleValue<OptionSelect>)?.value
    if (value || value === '') {
      setTicketStatus(value)
      setTicketById(null)
      getTicketList({ page: 0, status: value })
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch(ticketId)
    }
  }

  useEffect(() => {
    if (ticketList.length > 0) {
      getSurveyAnsweredList(ticketList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketList])

  return (
    <>
      <ContainerTicketsHeading>
        <Breadcrumb goToHome={true} />
        <div className="tickets-filters">
          <div className="search-ticket tickets-filter">
            <label htmlFor="searchTicket">Filtrar:</label>
            <input
              type="search"
              name="searchTicket"
              placeholder="Digite o número do chamado"
              onChange={(e) => {
                setTicketId(e.target.value)
              }}
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
            />
            <button
              type="button"
              title="Procurar"
              onClick={() => {
                handleSearch(ticketId)
              }}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </div>
          <div className="tickets-filter">
            <label htmlFor="filterTicket">Status:</label>
            <Select
              onChange={(e) => {
                handleSelect(e)
              }}
              optionValues={options}
              styles={styles}
              noOptMessage="Status não encontrado"
            />
          </div>
        </div>
      </ContainerTicketsHeading>
      <ContainerTicketsContent>
        {ticketList.length === 0 && <p>Nenhum chamado encontrado!</p>}
        {ticketList.length > 0 && (
          <div className="tickets-content">
            {ticketList.map((ticket) => (
              <div key={ticket.ticketID} className="wrapper_ticket">
                <div className="btn_notification">
                  <BtnSurveyTicket
                    ticket={ticket}
                    toggleModal={toggleSurveyModal}
                    surveysAnswered={surveysAnswered}
                  />
                </div>
                <Link to={`/ticket/detail/${ticket.ticketID}`} className="ticket-item">
                  <div className="ticket-item-label">
                    <span
                      style={{
                        backgroundColor: getColor(ticket.status)
                      }}
                    >
                      {ticket.status}
                    </span>
                    <span>{ticket.ticketID}</span>
                  </div>
                  <div className="ticket-item-content">
                    <div className="content-subject">
                      <span>Assunto:</span>
                      <span>{ticket.title}</span>
                    </div>
                    <div className="content-date">
                      <span>Data de abertura:</span>
                      <span>{ticket.createdAt}</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        )}
        <ModalWrapper ref={refSurveyModal} $width="70%">
          {isOpenModal && ticketSelected && (
            <TicketSurveyModal
              toggleModal={toggleSurveyModal}
              ticket={ticketSelected}
              updateData={() => {
                getTicketList({ page: pagination.page, status: ticketStatus })
              }}
            />
          )}
        </ModalWrapper>

        {ticketList.length > 0 && (
          <Pagination
            currentPage={pagination.page + 1}
            onNextPage={() => {
              getTicketList({ page: pagination.page + 1, status: ticketStatus })
            }}
            onPreviousPage={() => {
              getTicketList({ page: pagination.page - 1, status: ticketStatus })
            }}
            hasNextPage={pagination.next}
            hasPreviousPage={pagination.prev}
          />
        )}
      </ContainerTicketsContent>
    </>
  )
}

export default TicketListAll
