import { useState } from 'react'
import { toast } from 'react-toastify'
import { getAttributesWithPagination } from 'services/api'
import { AttributeModel } from '../entities'
import { filterAttributesByType } from './useGetAttributes'

export const useGetAttributesWithPagination = () => {
  const [attributes, setAttributes] = useState<AttributeModel[]>([])
  const [isLoadingAttributes, setIsLoadingForms] = useState(false)
  const [pagination, setPagination] = useState({
    next: true,
    prev: false,
    page: 0
  })
  const getAttributes = async (page = pagination.page) => {
    try {
      setIsLoadingForms(true)
      const response = await getAttributesWithPagination({ page, limit: 500 }) // FIX: REMOVE Limit

      if (!response) {
        throw new Error()
      }
      setAttributes(filterAttributesByType(response.data))
      setPagination({
        page,
        next: response.paginate.next,
        prev: response.paginate.prev
      })
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao recuperar as perguntas ')
    } finally {
      setIsLoadingForms(false)
    }
  }

  return {
    getAttributes,
    attributes,
    isLoadingAttributes,
    pagination,
    setAttributes
  }
}
