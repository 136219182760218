/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Controller,
  FieldValues,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext
} from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrip, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { WrapperOptionItem } from '../AttributeFields/styles'

type Props = {
  optIndex: number
  remove: UseFieldArrayRemove
  id: string | number
  option: any
  update: UseFieldArrayUpdate<FieldValues, any>
  optionsSelectElementFieldName: any
}

const SurveySelectItem = ({
  optIndex,
  remove,
  id,
  option,
  update,
  optionsSelectElementFieldName
}: Props) => {
  const { control, setValue } = useFormContext()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <>
      <WrapperOptionItem ref={setNodeRef} style={style}>
        <div className="section-option">
          <div className="values-opt">
            <button
              type="button"
              className="btn-move-opt"
              title="Mover"
              {...attributes}
              {...listeners}
            >
              <FontAwesomeIcon icon={faGrip} rotation={90} />
            </button>
            <FontAwesomeIcon icon={faCircle} />

            <Controller
              name={`${optionsSelectElementFieldName}.${optIndex}.value`}
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange } }) => (
                <div className="section-type">
                  <input
                    type="text"
                    placeholder="Opção"
                    defaultValue={option.value}
                    onChange={(e: any) => {
                      setValue(
                        `${optionsSelectElementFieldName}.${optIndex}.value`,
                        e.target.value
                      )
                    }}
                    onBlur={(e) => {
                      update(optIndex, { value: e.target.value, weight: option?.weight as number })
                    }}
                  />
                </div>
              )}
            />
          </div>
          <button
            type="button"
            className="btn-remove-opt"
            onClick={() => {
              remove(optIndex)
            }}
            title="Remover opção"
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </div>
      </WrapperOptionItem>
    </>
  )
}

export default SurveySelectItem
