import { useFieldArray, useFormContext } from 'react-hook-form'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import SurveySelectItem from './SurveySelectItem'

import { WrapperSelectField } from '../AttributeFields/styles'

export type SurveyQuestionOptionsProps = {
  value: string
  weight: number
}

type Props = {
  optionsSelectElementFieldName: any
}

const SurveySelectField = ({ optionsSelectElementFieldName }: Props) => {
  const { control, setValue } = useFormContext()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: optionsSelectElementFieldName
    // keyName: '_id'
  })

  const updateOptionPosition = (options: any) => {
    const _options: any[] = []
    options.forEach((opt: any, idx: number) => {
      _options.push({ ...opt, weight: idx + 1 })
    })
    return _options
  }

  const reorderList = (e: DragEndEvent) => {
    if (!e.over) return

    if (e.active.id !== e.over.id) {
      const oldIdx = fields.findIndex((el: any) => el.id === e.active.id)
      const newIdx = fields.findIndex((el: any) => el.id === e.over?.id)
      const _fields = arrayMove(fields, oldIdx, newIdx)

      const _newFields = updateOptionPosition(_fields)
      setValue(optionsSelectElementFieldName, _newFields)
    }
  }

  return (
    <DndContext onDragEnd={reorderList} autoScroll={false}>
      <SortableContext items={fields.map((el: any) => el._id)}>
        <WrapperSelectField>
          {fields.length > 0 &&
            fields.map((option, idx) => (
              <SurveySelectItem
                key={option.id}
                optIndex={idx}
                remove={remove}
                id={option.id}
                option={option}
                update={update}
                optionsSelectElementFieldName={optionsSelectElementFieldName}
              />
            ))}
          <button
            type="button"
            className="btn-add-option"
            onClick={() => {
              append({
                value: `Option ${fields.length + 1}`,
                weight: fields.length + 1
              })
            }}
            title="Nova opção"
          >
            <FontAwesomeIcon icon={faCircle} />
            <p>Adicione uma opção</p>
          </button>
        </WrapperSelectField>
      </SortableContext>
    </DndContext>
  )
}

export default SurveySelectField
