import axios from 'axios'
import {
  CategoryModel,
  TicketListPaginationModel,
  TicketModel,
  TicketToSubmitModel,
  UpdateTicketToSubmit,
  UserModel,
  UserProfileModel
} from '../entities'

const url = process.env.REACT_APP_CONNECTOR

export const api = axios.create({
  baseURL: url
})

export type GetNTicketsProps = {
  userLogin: string
  limit: number
  status: string
  page: number
}

export async function getNTickets (props: GetNTicketsProps): Promise<TicketListPaginationModel> {
  const { userLogin, limit, status, page } = props
  const response = await api.get(
    `api/ticket/findMany/${userLogin}?limit=${limit}&page=${page}&status=${status}`
  )
  return response.data
}

export async function getTicket (
  userEmail: string,
  numberTicket: string | number
): Promise<TicketModel> {
  const payload = {
    tj_user_email: userEmail,
    numberTicket
  }
  const response = await api.post('/api/ticket/find', payload)
  return response.data
}

export async function getUsersByName (userName: string): Promise<UserModel[]> {
  const response = await api.post('/user/find', { customer_name: userName })
  return response.data
}

export async function storeTicket (payload: TicketToSubmitModel): Promise<{ ticket: string }> {
  const response = await api.post('/api/ticket', payload)
  return response.data
}

export async function getUserProfile (userLogin: string): Promise<UserProfileModel> {
  const response = await api.post('/user/show', { userLogin })
  return response.data
}

export async function updateStatusTicket (
  payload: UpdateTicketToSubmit,
  ticketID: string
): Promise<any> {
  const response = await api.put(`/api/ticket/user-update-status/${ticketID}`, payload)
  return response.data
}

export async function getCAFileBase64 (fileName: string): Promise<any> {
  const response = await api.get(`/api/file/${fileName}`)
  return response.data
}

export async function getCategoryByName (
  isRequest: boolean,
  name: string
): Promise<CategoryModel[]> {
  const response = await api.get(`/api/categories?isRequest=${isRequest.toString()}&name=${name}`)
  return response.data
}
