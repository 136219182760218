import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../entities'

import { ErrorMessage, FormInputCPF } from './styles'

const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

type Props = {
  attribute: AttributeModel
}

const CpfField = ({ attribute }: Props) => {
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext()
  const isRequired = attribute.required ?? false

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={{ required: isRequired, minLength: 14 }}
      render={({ field: { onChange } }) => (
        <FormInputCPF>
          <label htmlFor={attribute.id.toString()}>{attribute.question}</label>
          <input
            type="text"
            maxLength={14}
            placeholder="000.000.000-00"
            value={getValues()[attribute.id.toString()] ?? ''}
            onChange={(e) => {
              onChange(cpfMask(e.target.value))
            }}
          />
          {errors[attribute.id.toString()]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
          {errors[attribute.id.toString()]?.type === 'minLength' && (
            <ErrorMessage>* Deve ser um CPF válido</ErrorMessage>
          )}
        </FormInputCPF>
      )}
    />
  )
}

export default CpfField
