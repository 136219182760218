import { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { ModalContent } from 'components'
import { EditorContentField, ImageFileField } from 'components/Fields'
import { deleteFilePublic } from 'services/fileManager'
import { createNews } from 'services/api'
import { getUrlFile, reactQuillContainerParams, validations } from './utils'
import { INews } from '../../entities'
import PreviewNewsModal from './PreviewNewsModal'

import { ModalBodyForm } from 'components/Modal/styles'
import { ModalWrapper } from 'pages/Admin/styles'

export interface IFormInputNews {
  image: File
  content: string
}

type Props = {
  toggleModal: () => void
  listLength: number
  getNews: () => void
}

const AddNewsModal = ({ toggleModal, listLength, getNews }: Props) => {
  const methods = useForm<IFormInputNews>()
  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [previewContent, setPreviewContent] = useState<null | Pick<
  INews,
  'imageUrlData' | 'description'
  >>(null)
  const [isLoading, setIsLoading] = useState(false)

  const modules = useMemo(
    () => ({
      toolbar: {
        container: reactQuillContainerParams
      }
    }),
    []
  )

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        refPreviewModal?.current.close()
        return
      }
      const data = {
        imageUrlData: methods.getValues('image') ? URL.createObjectURL(methods.getValues('image')) : '',
        description: methods.getValues('content')
      }

      setPreviewContent(data)
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const onSubmit: SubmitHandler<IFormInputNews> = async (data: IFormInputNews): Promise<void> => {
    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }
    setIsLoading(true)
    const urlImage = await getUrlFile(data.image)
    try {
      const response = await createNews(data.content, urlImage, listLength + 1)
      if (!response) {
        throw new Error()
      }
      getNews()
    } catch (error: any) {
      await deleteFilePublic(urlImage)
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao adicionar o destaque')
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (listLength === 10) {
      toast.error('Já existem 10 destaques!')
      handleCloseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listLength])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Novo destaque"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        // handleOpenModal={togglePreviewModal}
        // btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <div className="news-image-adm">
            <ImageFileField
              attribute={{
                id: 'image',
                question: '',
                optionsSelectElement: [],
                required: true,
                isHiddenToVip: false
              }}
            />
          </div>
          <EditorContentField
            modulesEditor={modules}
            validations={validations}
            fieldName="content"
            label="Adicione um texto"
          />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal} $width={'40%'}>
        {isOpenPreviewModal && (
          <PreviewNewsModal news={previewContent} toggleModal={togglePreviewModal} />
        )}
      </ModalWrapper>
    </FormProvider>
  )
}

export default AddNewsModal
