import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const Form = styled.form<{ $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-size: 1rem;
  padding: 2rem 5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 1rem;
  color: ${({ theme }) => theme.colors.blue._800};
  margin-bottom: 2rem;

  .form-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: 5rem;
    min-width: 18rem;
    width: fit-content;
    padding: 1rem 3.5rem;
    border-radius: 0 3.5rem 3.5rem 0;
    background-color: ${(props) => props.$color ?? props.theme.colors.gray._450};
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 2rem;

    // According Form padding (file: components/TicketForm/styles.js)
    margin-left: -5rem;

    span:nth-child(1) {
      font-size: 1rem;
      font-weight: normal;
      text-transform: uppercase;
    }

    span:nth-child(2) {
      font-size: 1.15rem;
      font-weight: bold;
    }
  }

  h2 {
    width: 100%;
    display: block;
    text-transform: uppercase;
    font-size: 1.25rem;
  }

  .form-inputs {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 2rem 0;
    gap: 1.5rem;

    label {
      font-weight: bold;
      margin-bottom: 0.75rem;
    }
  }

  .form-button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    padding: 2rem;
    h2 {
      font-size: 1.15rem;
    }

    .form-label {
      padding: 1rem 2rem;
      // According to Form padding (file: components/TicketForm/styles.js)
      margin-left: -2rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    padding: 2rem 3rem;
    h2 {
      font-size: 1.15rem;
    }
    .form-label {
      padding: 1rem 3rem;
      // According to Form padding (file: components/TicketForm/styles.js)
      margin-left: -3rem;
    }
  }
`
