import { ReactNode, createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { authenticate } from '../services/authApi'
import { createSession } from '../services/api'
import { getUserProfile } from '../services/connector'

type SaveInLocalStorageProps = {
  token: string
  userName: string
  userLogin: string
  userEmail: string
  role: string
}

export type PropsContext = {
  login: (userName: string, password: string) => void
  logout: () => void
  errorMessage: string
  isLoading: boolean
}
type Props = {
  children: ReactNode
}

const initialState = {
  login: () => {},
  logout: () => {}
}

export const AuthContext = createContext<PropsContext>(initialState as unknown as PropsContext)

export const AuthProvider = ({ children }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const saveDataInLocalStorage = async (props: SaveInLocalStorageProps) => {
    const { token, userName, userLogin, role, userEmail } = props
    localStorage.setItem('token', token)

    try {
      const response = await getUserProfile(userLogin)
      if (!response) {
        throw new Error()
      }
      const userVip = 'Magistrado/Alta gestão' || 'VIP' || 'Chefe'
      const isHighProfile: boolean = response?.user_profile === userVip
      localStorage.setItem('isHidden', isHighProfile.toString())

      const user = {
        userName,
        userLogin,
        role,
        userEmail
      }

      localStorage.setItem('user', JSON.stringify(user))
    } catch (error) {
      localStorage.setItem('isHidden', 'false')
    }
  }

  const login = async (username: string, password: string) => {
    try {
      setIsLoading(true)
      const respUser = await authenticate(username.toLowerCase(), password)
      if (!respUser) {
        throw new Error()
      }
      const respSession = await createSession(respUser.name, respUser.username)
      if (!respSession) {
        throw new Error()
      }

      await saveDataInLocalStorage({
        token: respSession.token,
        userName: respUser.name,
        userLogin: respUser.username,
        userEmail: Array.isArray(respUser.mail) ? '' : respUser.mail,
        role: respSession?.user?.roles
      })
      setIsLoading(false)
      navigate('/')
      window.location.reload() // Prevents the chatbot appearing on the homepage
    } catch (error) {
      setIsLoading(false)
      setErrorMessage('Usuário ou senha Incorretos!')
    }
  }

  const logout = async () => {
    sessionStorage.clear()
    localStorage.clear()
    navigate('/login')
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isLoading,
        errorMessage
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
