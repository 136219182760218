import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AddAdminModal,
  AddButton,
  AdmLayout,
  DeleteAdminModal,
  EditAdminModal,
  Spinner
} from 'components'
import { getAdminList } from 'services/api'
import { UserAdminModel } from '../../../entities'

import {
  EmptyListMessage,
  ModalWrapper,
  TitlePage,
  WrapperAdminList,
  WrapperContent
} from '../styles'

export const AdminManagement = () => {
  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const [adminList, setAdminList] = useState<UserAdminModel[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [adminSelected, setAdminSelected] = useState<UserAdminModel | null>(null)

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const getAdmins = async () => {
    try {
      setIsLoading(true)
      const response = await getAdminList()
      if (!response) {
        throw new Error()
      }
      setAdminList(response)
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao carregar os administradores')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!isOpenAddModal && !isOpenEditModal && !isOpenDeleteModal) {
      getAdmins()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdmLayout title="Gerenciamento de administradores">
      <WrapperContent>
        <TitlePage>Administrador</TitlePage>
        <AddButton text="Adicionar novo administrador" onClick={toggleAddModal} />
        <WrapperAdminList>
          <label>Administradores:</label>

          {isLoading && <Spinner />}
          {!isLoading && adminList.length === 0 && (
            <EmptyListMessage>
              <p>Nenhum administrador encontrado!</p>
            </EmptyListMessage>
          )}
          {!isLoading && adminList.length > 0 && (
            <table className="admin-list-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {adminList.map((admin) => (
                  <tr key={admin.id}>
                    <td>{admin.name}</td>
                    <td>
                      <button
                        onClick={() => {
                          setAdminSelected(admin)
                          toggleEditModal()
                        }}
                        title='Editar'
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        onClick={() => {
                          setAdminSelected(admin)
                          toggleDeleteModal()
                        }}
                        title='Remover'
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperAdminList>
      </WrapperContent>
      <ModalWrapper ref={refAddModal}>
        {isOpenAddModal && <AddAdminModal toggleModal={toggleAddModal} getAdmins={ () => { getAdmins() }} />}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal}>
        {isOpenEditModal && adminSelected && (
          <EditAdminModal toggleModal={toggleEditModal} adminSelected={adminSelected} getAdmins={ () => { getAdmins() }}/>
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && adminSelected && (
          <DeleteAdminModal toggleModal={toggleDeleteModal} adminSelected={adminSelected} getAdmins={ () => { getAdmins() }}/>
        )}
      </ModalWrapper>
    </AdmLayout>
  )
}
