import { useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ModalContent } from 'components'
import { CardModel } from '../../entities'
import InformativeModal from './InformativeModal'
import { CreateCardProps, createCard } from 'services/api'
import { ColorField, IconField, TextField } from 'components/Fields'

import { ModalWrapper } from 'pages/Admin/styles'
import { ModalBodyForm } from 'components/Modal/styles'

interface IFormInput {
  name: string
  color: string
  icon: string
  image?: string
  isEnable: boolean
}

type Props = {
  toggleAddModal: () => void
  updateData: () => void
  cardSelected: CardModel | null
  listLength: number
}

const AddCardModal = ({
  toggleAddModal,
  updateData,
  cardSelected,
  listLength
}: Props) => {
  const methods = useForm<IFormInput>()
  const refInfoModal = useRef<HTMLDialogElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false)

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    setIsLoading(true)
    try {
      const dto: CreateCardProps = {
        name: data.name,
        icon: data.icon,
        color: data.color,
        systemTypes: null,
        birthOrder: listLength,
        isEnable: true,
        parent: cardSelected ? {
          id: cardSelected?.id
        } : null,
        children: []
      }

      const response = await createCard(dto)
      if (!response) {
        throw new Error()
      }
      updateData()
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao adicionar um novo card')
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleAddModal()
  }

  const toggleInfoModal = () => {
    if (refInfoModal.current) {
      if (isOpenInfoModal) {
        setIsOpenInfoModal(false)
        refInfoModal?.current.close()
        return
      }
      setIsOpenInfoModal(true)
      refInfoModal?.current.showModal()
    }
  }

  return (
    <>
      <ModalContent
        title="Novo card"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <FormProvider {...methods}>
          <ModalBodyForm>
            <TextField
              attribute={{
                id: 'name',
                question: 'Nome do card',
                required: true,
                isHiddenToVip: false,
                optionsSelectElement: [],
                maxLength: 50
              }}
            />
            <IconField
              name="icon"
              isRequired={true}
              toggleInfoModal={toggleInfoModal}
              defaultValue={cardSelected?.icon as IconProp}
            />
            <ColorField name="color" isRequired={true} defaultValue={cardSelected?.color} />
          </ModalBodyForm>
        </FormProvider>
      </ModalContent>
      <ModalWrapper ref={refInfoModal}>
        {isOpenInfoModal && <InformativeModal toggleModal={toggleInfoModal} />}
      </ModalWrapper>
    </>
  )
}

export default AddCardModal
