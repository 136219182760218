import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  AddButton,
  AddCardModal,
  AdmLayout,
  BreadcrumbsAdm,
  CardAdminTable,
  DeleteCardModal,
  EditCardModal,
  EnableCardModal,
  InfoFormModal,
  SortCardsModal,
  Spinner
} from 'components'
import { useGetCardList } from 'hooks'
import { getItemSelected } from 'pages/Service/utils'
import { CardModel } from '../../../entities'

import {
  EmptyListMessage,
  ModalWrapper,
  TitlePage,
  WrapperCardList,
  WrapperContent
} from '../styles'

export const CardAdmin = () => {
  const { cardId, cardIdL1, cardIdL2 } = useParams()

  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const refEnableCardModal = useRef<HTMLDialogElement>(null)
  const refSortCardsModal = useRef<HTMLDialogElement>(null)
  const refInfoFormModal = useRef<HTMLDialogElement>(null)

  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenEnableCardModal, setIsOpenEnableCardModal] = useState(false)
  const [isOpenSortCardsModal, setIsOpenSortCardsModal] = useState(false)
  const [isOpenInfoFormModal, setIsOpenInfoFormModal] = useState(false)

  const [cardSelected, setCardSelected] = useState<CardModel | null>(null)
  const [cardsToShow, setCardsToShow] = useState<CardModel[] | []>([])
  const [cardHasForm, setCardHasForm] = useState(false)

  const { isLoadingCardList, cardList, getCardList } = useGetCardList()

  const checkFormExists = () => {
    if (cardHasForm) {
      toggleInfoFormModal()
      return
    }
    toggleAddModal()
  }

  const toggleInfoFormModal = () => {
    if (refInfoFormModal.current) {
      if (isOpenInfoFormModal) {
        setIsOpenInfoFormModal(false)
        refInfoFormModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenInfoFormModal(true)
      refInfoFormModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEnableCardModal = () => {
    if (refEnableCardModal.current) {
      if (isOpenEnableCardModal) {
        setIsOpenEnableCardModal(false)
        refEnableCardModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEnableCardModal(true)
      refEnableCardModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleSortCardsModal = () => {
    if (refSortCardsModal.current) {
      if (isOpenSortCardsModal) {
        setIsOpenSortCardsModal(false)
        refSortCardsModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenSortCardsModal(true)
      refSortCardsModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  useEffect(() => {
    setCardHasForm(false)

    if (!cardId) {
      setCardSelected(null)
      setCardsToShow(cardList)
    }

    if (cardList.length > 0 && cardId) {
      const card = getItemSelected({
        list: cardList,
        idL1: cardIdL1,
        idL2: cardIdL2,
        id: cardId
      })

      setCardSelected(card as CardModel)
      setCardsToShow(card?.children ?? ([] as CardModel[]))
      if (card?.forms && card?.forms.length > 0) {
        setCardHasForm(true)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId, cardList])

  return (
    <AdmLayout title="Administração de Cards">
      <WrapperContent>
        <TitlePage>Cards</TitlePage>
        <AddButton
          text="Adicionar novo card"
          onClick={checkFormExists}
          title="Adicionar novo card"
        />
        <WrapperCardList>
          {cardSelected?.name ? <BreadcrumbsAdm cardList={cardList} /> : <label>Cards:</label>}

          {isLoadingCardList && <Spinner />}
          {!isLoadingCardList && cardsToShow.length === 0 && (
            <EmptyListMessage>
              <p>Nenhum card encontrado!</p>
            </EmptyListMessage>
          )}
          {!isLoadingCardList && cardsToShow.length > 0 && (
            <CardAdminTable
              cards={cardsToShow}
              setCardSelected={setCardSelected}
              toggleEditModal={toggleEditModal}
              toggleDeleteModal={toggleDeleteModal}
              toggleEnableCardModal={toggleEnableCardModal}
              toggleSortCardsModal={toggleSortCardsModal}
              setCardsToShow={setCardsToShow}
            />
          )}
        </WrapperCardList>
      </WrapperContent>
      <ModalWrapper ref={refEnableCardModal}>
        {isOpenEnableCardModal && cardSelected && (
          <EnableCardModal
            cardSelected={cardSelected}
            toggleModal={toggleEnableCardModal}
            updateData={() => {
              getCardList()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refAddModal}>
        {isOpenAddModal && (
          <AddCardModal
            toggleAddModal={toggleAddModal}
            updateData={() => {
              getCardList()
            }}
            cardSelected={cardSelected}
            listLength={cardSelected ? cardSelected?.children.length + 1 : cardsToShow.length + 1}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal}>
        {isOpenEditModal && cardSelected && (
          <EditCardModal
            toggleModal={toggleEditModal}
            cardSelected={cardSelected}
            updateData={() => {
              getCardList()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && cardSelected && (
          <DeleteCardModal
            toggleModal={toggleDeleteModal}
            cardSelected={cardSelected}
            updateData={() => {
              getCardList()
            }}
          />
        )}
      </ModalWrapper>

      <ModalWrapper ref={refSortCardsModal}>
        {isOpenSortCardsModal && cardsToShow && (
          <SortCardsModal
            toggleModal={toggleSortCardsModal}
            cardListSort={cardsToShow}
            updateData={() => {
              getCardList()
            }}
          />
        )}
      </ModalWrapper>

      <ModalWrapper ref={refInfoFormModal}>
        {isOpenInfoFormModal && cardSelected && (
          <InfoFormModal
            toggleModal={toggleInfoFormModal}
            cardName={cardSelected?.name}
            formName={cardHasForm ? cardSelected?.forms[0]?.title : ''}
            toggleNextModal={toggleAddModal}
          />
        )}
      </ModalWrapper>
    </AdmLayout>
  )
}
