import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const ContainerTicketsHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .search-ticket {
    input[type='search'] {
      min-width: 15rem;
    }

    button {
      background-color: inherit;
      border: none;
      width: 1.35rem;
      height: 1.35rem;
      margin-left: -3rem;
      cursor: pointer;
      svg {
        width: 1.35rem;
        height: 1.35rem;
        color: ${({ theme }) => theme.colors.blue._800};

        &:hover {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  .tickets-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    .tickets-filter {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.75rem;
      color: ${({ theme }) => theme.colors.white};

      label {
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .search-ticket {
      input[type='search'] {
        min-width: 15rem;
      }

      button {
        background-color: inherit;
        border: none;
        width: 1.35rem;
        height: 1.35rem;
        margin-left: -3rem;
        cursor: pointer;
        svg {
          width: 1.15rem;
          height: 1.15rem;
          color: ${({ theme }) => theme.colors.blue._800};

          &:hover {
            width: 1.35rem;
            height: 1.35rem;
          }
        }
      }
    }
  }

  ${breakQueriesMaxMin(700, SizeBreakpoint.xsm)} {
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    font-size: 0.75;

    .tickets-filters {
      flex-direction: column;
      align-items: start;
      justify-content: flex-start;
      gap: 0.75rem;
      margin-bottom: 0.75rem;
      .tickets-filter {
        color: ${({ theme }) => theme.colors.blue._800};
      }
    }
  }
`

export const ContainerTicketsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  min-height: 10rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.gray._900};
  padding: 2rem 3rem;
  border-radius: 1rem;
  row-gap: 3rem;

  .tickets-content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    width: 100%;

    .wrapper_ticket {
      position: relative;

      .btn_notification {
        position: absolute;
        right: 0%;
        top: -8px;
      }
    }

    .ticket-item {
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.gray._100};
      border-radius: 0 1.625rem 1.625rem 1.625rem;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray._400};
      }

      .ticket-item-label {
        display: flex;
        font-size: 0.75rem;
        font-weight: bold;

        span:first-child {
          width: 10rem;
          padding: 0.25rem 1rem 0.25rem 0.75rem;
          border-radius: 0px 13px 13px 0px;
          z-index: 1;
          background-color: red;
          color: ${({ theme }) => theme.colors.white};
          text-transform: uppercase;
        }

        span:last-child {
          width: 7rem;
          padding: 0.25rem 0.75rem 0.25rem 1.75rem;
          background-color: ${({ theme }) => theme.colors.gray._400};
          border-radius: 0 0.8215rem 0.8215rem 0;
          margin-left: -1rem;
          z-index: 0;
          color: ${({ theme }) => theme.colors.gray._500};
        }
      }

      .ticket-item-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        padding: 1rem 1.5rem;
        color: ${({ theme }) => theme.colors.gray._500};
        gap: 0.65rem;

        .content-subject {
          display: flex;
          flex-direction: column;
          gap: 0.15rem;
          span:first-child {
            font-size: 0.75rem;
          }
          span:last-child {
            font-weight: bold;
          }
        }

        .content-date {
          display: flex;
          flex-direction: column;
          gap: 0.15rem;
          span:first-child {
            font-size: 0.65rem;
          }
          span:last-child {
            font-size: 0.75rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .btn_load_more {
    margin-top: 3rem;
    width: 16rem;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    .tickets-content {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    .tickets-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.sm)} {
    padding: 2rem;
    .tickets-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    padding: 2rem;
    .tickets-content {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }
  }
`
