import { useEffect, useRef, useState } from 'react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AddButton,
  AdmLayout,
  CreateSurveyModal,
  DeleteSurveyModal,
  EditSurveyModal,
  EnableSurveyModal,
  Pagination,
  Spinner
} from 'components'
import { SurveyTemplateModel } from '../../../entities'
import { useGetSurveyList } from 'hooks'

import {
  EmptyListMessage,
  ModalWrapper,
  TitlePage,
  WrapperContent,
  WrapperSurveyList
} from '../styles'

export const SurveyAdmin = () => {
  const { getSurveys, isLoadingSurveys, surveyList, pagination } = useGetSurveyList()

  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const refEnableModal = useRef<HTMLDialogElement>(null)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenEnableModal, setIsOpenEnableModal] = useState(false)

  const [surveySelected, setSurveySelected] = useState<SurveyTemplateModel | null>(null)

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }
  const toggleEnableModal = () => {
    if (refEnableModal.current) {
      if (isOpenEnableModal) {
        setIsOpenEnableModal(false)
        refEnableModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEnableModal(true)
      refEnableModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  useEffect(() => {
    getSurveys(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AdmLayout title="Administração de feedback">
      <WrapperContent>
        <TitlePage>Pesquisas de satisfação</TitlePage>
        <AddButton text="Adicionar nova pesquisa" onClick={toggleAddModal} />
        <WrapperSurveyList>
          <label>Pesquisas cadastradas:</label>

          {isLoadingSurveys && <Spinner />}
          {!isLoadingSurveys && surveyList.length === 0 && (
            <EmptyListMessage>
              <p>Nenhuma pesquisa encontrada!</p>
            </EmptyListMessage>
          )}
          {!isLoadingSurveys && surveyList.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th className="column-title">Título</th>
                  <th className="column-action">Ação</th>
                  <th className="column-active">Ativo</th>
                </tr>
              </thead>
              <tbody>
                {surveyList.map((survey) => (
                  <tr key={survey.id}>
                    <td className="column-title">{survey.title}</td>
                    <td className="column-action">
                      <button
                        className="action-edit"
                        onClick={() => {
                          setSurveySelected(survey)
                          toggleEditModal()
                        }}
                        title="Editar"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className="action-remove"
                        onClick={() => {
                          setSurveySelected(survey)
                          toggleDeleteModal()
                        }}
                        title="Remover"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </td>
                    <td className="column-active">
                      <input
                        type="checkbox"
                        checked={survey.isEnable}
                        onChange={() => {
                          setSurveySelected(survey)
                          toggleEnableModal()
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperSurveyList>
        {!isLoadingSurveys && surveyList.length > 0 && (
          <Pagination
            currentPage={pagination.page + 1}
            onNextPage={() => {
              getSurveys(pagination.page + 1)
            }}
            onPreviousPage={() => {
              getSurveys(pagination.page - 1)
            }}
            hasNextPage={pagination.next}
            hasPreviousPage={pagination.prev}
          />
        )}
      </WrapperContent>
      <ModalWrapper ref={refAddModal} $width="70%">
        {isOpenAddModal && (
          <CreateSurveyModal
            toggleModal={toggleAddModal}
            updateData={() => {
              getSurveys(0)
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal} $width="70%">
        {isOpenEditModal && surveySelected && (
          <EditSurveyModal
            toggleModal={toggleEditModal}
            surveySelected={surveySelected}
            updateData={() => {
              getSurveys()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && surveySelected && (
          <DeleteSurveyModal
            toggleModal={toggleDeleteModal}
            surveySelected={surveySelected}
            updateData={() => {
              getSurveys()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEnableModal} $width="70%">
        {isOpenEnableModal && surveySelected && (
          <EnableSurveyModal
            surveySelected={surveySelected}
            toggleModal={toggleEnableModal}
            updateData={() => {
              getSurveys()
            }}
          />
        )}
      </ModalWrapper>
    </AdmLayout>
  )
}
