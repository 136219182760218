import { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { getNTickets } from '../services/connector'
import { IPartialTicket, TicketModel } from '../entities'
import { AuthContext } from 'contexts/auth'

export const formatTicketListData = (list: TicketModel[]) => {
  const dataFormatted = [] as IPartialTicket[]
  list.forEach((item: TicketModel) => {
    dataFormatted.push({
      ticketID: item.ca_id ?? 'ID inválido, por favor, entre em contato conosco.',
      title: item.ca_suumary ?? 'Chamado sem título',
      createdAt: item.open_date ?? 'Chamado sem data definida',
      status: item.ca_status ?? 'Status do chamado desconhecido',
      persistedId: item.ca_persisted_id ?? 'Persisted id desconhecido'
    })
  })
  return dataFormatted
}

export type GetTicketListProps = {
  limit?: number
  status?: string
  page: number
}

export const useGetTickets = () => {
  const [ticketList, setTicketList] = useState<[] | IPartialTicket[]>([])
  const [isLoadingTickets, setLoadingTickets] = useState(false)
  const [pagination, setPagination] = useState({
    next: true,
    prev: false,
    page: 0
  })
  const { logout } = useContext(AuthContext)

  const getTicketList = async ({ limit = 12, page, status = '' }: GetTicketListProps) => {
    setLoadingTickets(true)
    try {
      if (!localStorage.getItem('user')) {
        toast.error(
          'Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente'
        )
        setTimeout(() => {
          logout()
        }, 3000)
        return
      }

      const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin

      const response = await getNTickets({ userLogin, limit, status, page })
      if (!response) {
        setTicketList([])
        return
      }

      const ticketListFormatted = formatTicketListData(response.tickets)
      setTicketList(ticketListFormatted)
      setPagination({
        page,
        next: response.pagination.next,
        prev: response.pagination.prev
      })
    } catch (error) {
      toast.error('Ops 😥, ocorreu um erro ao recuperar os chamados ')
    } finally {
      setLoadingTickets(false)
    }
  }

  return {
    ticketList,
    isLoadingTickets,
    getTicketList,
    pagination
  }
}
