/* eslint-disable quote-props */
import { useState } from 'react'
import { toast } from 'react-toastify'
import { IPartialTicket } from '../entities'
import { getSurveysAnswered } from 'services/api'

export const useGetTicketSurveysAnswered = () => {
  const [surveysAnswered, setSurveysAnswered] = useState<null | Record<string, boolean>>(null)

  const getSurveyAnsweredList = async (ticketList: IPartialTicket[]) => {
    try {
      let link = ''
      ticketList.forEach((ticket) => {
        if (ticket.status.toUpperCase() === 'ENCERRADO') {
          link += `ids[]=${ticket.ticketID}&`
        }
      })
      if (link !== '') {
        const userLogin = JSON.parse(localStorage.getItem('user') as string).userLogin
        const response = await getSurveysAnswered(link, userLogin)
        setSurveysAnswered(response)
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um erro ao recuperar a pesquisa ')
    }
  }

  return {
    getSurveyAnsweredList,
    surveysAnswered
  }
}
