import styled from 'styled-components'
import { breakQueriesMax, breakQueriesMaxMin, SizeBreakpoint } from '../../../styles/Breakpoints'
import backgroundTJ from '../../../assets/background-tj.png'
import { btnSize } from 'components/Home/styles'

//* ************************* */
// Styles: Header component
//* ************************* */
export const ContainerHeader = styled.header<{ $isChangedHeight: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  padding: 2rem ${btnSize.lg};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: ${(props) => (props.$isChangedHeight ? '20rem' : '15rem')} !important;
  min-height: ${(props) => (props.$isChangedHeight ? '20rem' : '15rem')} !important;

  width: 100%;
  background-image: url(${backgroundTJ});
  color: ${({ theme }) => theme.colors.white};

  .header-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    margin-left: 1rem;
    margin-bottom: 0.25rem;
    h1 {
      font-size: 3.15rem;
      color: ${({ theme }) => theme.colors.blue._100};
    }

    p {
      font-size: 1.15rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    .header-content {
      h1 {
        font-size: 2.25rem;
        margin-bottom: 0.15rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    .header-content {
      h1 {
        font-size: 2.35rem;
        margin-bottom: 0.25rem;
      }

      p {
        font-size: 1.15rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    .header-content {
      h1 {
        font-size: 2.5rem;
        margin-bottom: 0.35rem;
      }

      p {
        font-size: 1.15rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    .header-content {
      h1 {
        font-size: 2.85rem;
        margin-bottom: 0.35rem;
      }

      p {
        font-size: 1.25rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xxl, SizeBreakpoint.xl)} {
    height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '18rem' : '13rem')} !important;
    .header-content {
      h1 {
        font-size: 3rem;
        margin-bottom: 0.35rem;
      }

      p {
        font-size: 1.25rem;
      }
    }
  }

  @media (max-height: 800px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    height: ${(props) => (props.$isChangedHeight ? '15.5rem' : '11.5rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '15.5rem' : '11.5rem')} !important;
  }

  @media (min-height: 801px) and (max-height: 900px) and (min-width: 1366px ) {
    height: ${(props) => (props.$isChangedHeight ? '16rem' : '15rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '16rem' : '15rem')} !important;
  }

  @media (min-height: 901px) and (max-height: 1024px) and (min-width: 1366px ) {
    height: ${(props) => (props.$isChangedHeight ? '19rem' : '18rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '19rem' : '18rem')} !important;
    padding: 3rem ${btnSize.lg};
  }

  @media (min-height: 1025px) and (max-height: 1080px) and (min-width: 1366px ) {
    height: ${(props) => (props.$isChangedHeight ? '20rem' : '19rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '20rem' : '19rem')} !important;
    padding: 3.5rem ${btnSize.lg};
  }

  @media (min-height: 1081px) and (max-height: 1200px) and (min-width: 1366px ) {
    height: ${(props) => (props.$isChangedHeight ? '21rem' : '20rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '21rem' : '20rem')} !important;
    padding: 4.5rem ${btnSize.lg};
  }

  @media (min-height: 1201px) and (min-width: 1366px ) {
    height: ${(props) => (props.$isChangedHeight ? '23rem' : '22rem')} !important;
    min-height: ${(props) => (props.$isChangedHeight ? '23rem' : '22rem')} !important;
    padding: 4.5rem ${btnSize.lg};
  }
`

//* ************************* */
// Styles: HeaderMenu component
//* ************************* */
export const HeaderMenuBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  .link-with-border {
    border-right: 3px solid ${({ theme }) => theme.colors.white};
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    gap: 0.75rem;
  }
`

export const HeaderMenuLink = styled.div<{ $isActive: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-content: center !important;
  height: 2.25rem;
  padding-right: 1rem;
  font-size: 1.5rem;

  a {
    margin-top: 0.25rem;
    display: flex;
    flex-direction: column;
    color: ${(props) =>
      props.$isActive ? props.theme.colors.blue._100 : props.theme.colors.white};
    font-size: 1.25rem;
    font-weight: ${(props) => (props.$isActive ? 'bold' : 'normal')};
    cursor: pointer;

    span {
      background-color: ${(props) =>
        props.$isActive ? props.theme.colors.blue._100 : props.theme.colors.white};
      height: 2px;
      width: 1.5rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    a {
      font-size: 1rem;
      span {
        width: 1rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.xl, SizeBreakpoint.lg)} {
    a {
      font-size: 1.25rem;
      span {
        width: 1.15rem;
      }
    }
  }
`

//* ********************************* */
// Styles: HamburgerMenu Component
//* ********************************* */
export const ContainerHamburgerMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .hamburger-menu-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background: inherit;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 3px;
    padding: 0.5rem;
    cursor: pointer;

    svg {
      width: 1.35rem;
      height: 1.35rem;
      color: ${({ theme }) => theme.colors.white};
    }

    &:focus {
      outline: 1.2px solid ${({ theme }) => theme.colors.white};
    }

    &:hover {
      filter: brightness(0.9);
    }
  }

  .hamburger-menu-content {
    min-width: max-content;
    max-height: max-content;
    position: absolute;
    z-index: 999;
    border: 1px solid ${({ theme }) => theme.colors.gray._200};
    border-radius: 0.35rem;
    background: ${({ theme }) => theme.colors.white};
    padding: 0.5rem 0rem;
    transition: visibility 0.1s;

    margin-top: 5.5rem;

    margin-left: -2rem; //**** OBS  */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    color: ${({ theme }) => theme.colors.gray._500};

    .item {
      width: 100%;
      padding: 0.25rem 0.875rem;
      text-decoration: none;
      cursor: pointer;
      color: ${({ theme }) => theme.colors.gray._500};
      font-size: 1rem;
      line-height: 1.25rem;
      font-weight: bold;
      line-height: 1.5;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray._200};
      }
    }

    .active {
      background-color: ${({ theme }) => theme.colors.gray._200};
    }
  }

  ${breakQueriesMax(SizeBreakpoint.md)} {
    .hamburger-menu-content {
      margin-top: 11rem;
    }
  }
`
