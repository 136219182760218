import { useParams } from 'react-router-dom'
import { Breadcrumb, ContainerLoading, Layout, Notification, TicketInfo } from '../../components'
import { useGetTicketDetails } from '../../hooks/useGetTicketDetails'

import { ContainerDetailsTicket } from './styles'

export function TicketDetails () {
  const { ticketID } = useParams()
  const { ticket, isLoadingTicket } = useGetTicketDetails(ticketID as string)

  return (
    <Layout title="Detalhes do Chamado">
      <ContainerDetailsTicket>
        <Breadcrumb titlePage="VISUALIZAR CHAMADO" goToHome={true} />
        <div className="details-ticket-content">
          {isLoadingTicket && <ContainerLoading />}
          {!isLoadingTicket && ticket && <TicketInfo ticket={ticket} />}
          {!isLoadingTicket && !ticket && (
            <Notification message="No momento não foi possível carregar os destalhes do chamado" />
          )}
        </div>
      </ContainerDetailsTicket>
    </Layout>
  )
}
