import { WrapperSurveyForm } from 'components/Survey/styles'
import { ModalContent, SurveyInputsToRender } from '..'
import { SurveyTemplateModel } from '../../entities'
import { ModalBodyInfo } from 'components/Modal/styles'

type Props = {
  toggleModal: () => void
  survey: SurveyTemplateModel
}

const PreviewSurveyModal = ({ toggleModal, survey }: Props) => {
  return (
    <ModalContent
      title={survey.title}
      handleCloseModal={toggleModal}
      handleSubmit={null}
      isLoading={false}
    >
      <ModalBodyInfo>
        <WrapperSurveyForm>
          {survey?.description && <p className="survey_description">{survey?.description}</p>}
          <div className="survey_form">
            <SurveyInputsToRender questions={survey.surveyQuestions} />
          </div>
        </WrapperSurveyForm>
      </ModalBodyInfo>
    </ModalContent>
  )
}

export default PreviewSurveyModal
