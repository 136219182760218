import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from 'styles/Breakpoints'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 1rem;
`

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  height: fit-content !important;
  padding: 1rem 0 1rem 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  /* z-index: 999; */
  border-radius: 1rem;
  gap: 1rem;
`

export const TopInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .wrapper-title,
  .title {
    text-align: left;
    font: normal normal bold 0.875rem Montserrat;
    color: ${({ theme }) => theme.colors.gray._500};
  }

  .id {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 9.375rem;
    height: 2.9rem;
    padding: 0.5rem 1rem 0.5rem 2rem;
    background: ${({ theme }) => theme.colors.gray._300} 0% 0% no-repeat padding-box;
    border-radius: 56px 0px 0px 56px;
    opacity: 1;

    span:nth-child(1) {
      text-align: left;
      font: normal normal normal 0.875rem Montserrat;
      color: ${({ theme }) => theme.colors.gray._500};
    }

    span:nth-child(2) {
      text-align: left;
      font: normal normal bold 0.75rem Montserrat;
      color: ${({ theme }) => theme.colors.gray._500};
    }
  }

  ${breakQueriesMaxMin(1280, 640)} {
    .id {
      width: 8.125rem;
    }
  }

  ${breakQueriesMaxMin(640, 500)} {
    .wrapper-title,
    .title {
      font: normal normal bold 0.75rem Montserrat;
    }
    .id {
      width: 6.25rem;
    }
  }

  ${breakQueriesMaxMin(500, SizeBreakpoint.xsm)} {
    display: flex;
    flex-direction: column-reverse;

    .wrapper-title {
      display: flex;
      flex-wrap: wrap;
      align-self: flex-start !important;
    }

    .title {
      margin-top: 0.75rem;
      font: normal normal bold 0.75rem Montserrat;
    }

    .id {
      align-self: flex-end;
      width: 6.25rem;

      span:nth-child(1) {
        font: normal normal normal 0.875rem Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 0.75rem Montserrat;
      }
    }
  }
`

export const TopDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;

  .ticket-info-btn {
    margin-right: 1.5rem;
    padding: 0.625rem 0.875rem;
    border: none;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.blue._800};
    color: ${({ theme }) => theme.colors.white};
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }

  @media (max-width: 768px) {
    .ticket-info-btn {
      font-size: 0.875rem;
    }
  }

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    .ticket-info-btn {
      margin-top: 1rem;
      font-size: 0.875rem;
    }
  }
`

export const Information = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.5rem;
  }

  .line {
    display: flex;
    width: 2px;
    height: 3rem;
    border: 2px solid ${({ theme }) => theme.colors.blue._800};
    opacity: 1;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .status,
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;

    span:nth-child(1) {
      text-align: left;
      font: normal normal normal 0.875rem Montserrat;
      color: ${({ theme }) => theme.colors.gray._500};
    }

    span:nth-child(2) {
      text-align: left;
      font: normal normal bold 1rem Montserrat;
      text-transform: uppercase;
    }
  }

  .info {
    span:nth-child(2) {
      color: ${({ theme }) => theme.colors.blue._800};
    }
  }

  @media (max-width: 1536px) {
    .line {
      height: 3rem;
    }
  }

  @media (max-width: 1380px) {
    .line {
      height: 2.8rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

  @media (max-width: 1280px) {
    .line {
      height: 2.5rem;
    }
    .status,
    .info {
      span:nth-child(1) {
        font: normal normal normal 0.75rem Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 0.875rem Montserrat;
      }
    }
  }

  @media (max-width: 1024px) {
    .line {
      height: 2.375rem;
    }
    .status,
    .info {
      span:nth-child(1) {
        font: normal normal normal 0.75rem Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 0.75rem Montserrat;
      }
    }
  }

  @media (max-width: 768px) {
    .line {
      height: 2.2rem;
      margin-left: 0.7rem;
      margin-right: 0.7rem;
    }
    .status,
    .info {
      span:nth-child(1) {
        font: normal normal normal 0.75rem Montserrat;
      }
    }
  }

  @media (max-width: 640px) {
    .line:nth-of-type(4) {
      display: none;
    }

    .line {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }
  }

  @media (max-width: 320px) {
    .line:nth-of-type(4) {
      display: none;
    }

    .line {
      margin-left: 0.7rem;
      margin-right: 0.7rem;
    }

    .status,
    .info {
      span:nth-child(1) {
        font: normal normal normal 0.625rem Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 0.75rem Montserrat;
      }
    }
  }
`

export const MiddleContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  min-height: 10rem;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  margin-top: 1rem;

  .response {
    text-align: left;
    font: normal normal normal 1rem Montserrat;
    letter-spacing: 0px;
    opacity: 1;
  }

  @media (max-width: 1280px) {
    .response {
      font: normal normal normal 0.875rem Montserrat;
    }
  }
`

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  width: 100%;
  min-height: 9rem;
  height: fit-content;
  padding: 1.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;

  .text {
    text-align: left;
    font: normal normal normal 1rem Montserrat;
    margin-top: 1rem;
  }

  @media (max-width: 1280px) {
    .text {
      font: normal normal normal 0.875rem Montserrat;
    }
  }
`

export const LabelContent = styled.div`
  text-align: left;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.blue._800};
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal bold 1.125rem Montserrat;

  @media (max-width: 1280px) {
    .text {
      font: normal normal normal 1rem Montserrat;
    }
  }
`

export const WrapperFollowup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-top: 1rem;
  width: 100%;
  height: fit-content;

  .followup-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .followup-info__date,
    .followup-info__name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    svg {
      font-size: 0.5rem;
      margin-right: 0.5rem;
      color: ${({ theme }) => theme.colors.gray._600};
    }

    .create-info {
      text-align: left;
      font: normal normal bold 1rem Montserrat;
      color: ${({ theme }) => theme.colors.gray._600};
    }

    .data-followup {
      font: normal normal bold 1rem Montserrat;
      color: ${({ theme }) => theme.colors.blue._600};
    }
  }

  .followup-description {
    margin-left: 1.5rem;
    margin-top: 0.5rem;

    .followup-description__text {
      font: normal normal normal 1rem Montserrat;
    }

    .followup-description__file {
      display: flex;
      span {
        font: normal normal normal 1rem Montserrat;
      }

      .file {
        display: flex;
        gap: 1rem;
        button {
          border: none;
          background-color: transparent;
          text-decoration: underline;
          color: ${({ theme }) => theme.colors.blue._600};
          font: normal normal normal 1rem Montserrat;
          cursor: pointer;
          margin-left: 1rem;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .followup-info,
    .followup-description {
      .create-info,
      .data-followup {
        font: normal normal bold 0.875rem Montserrat;
      }
      span {
        font: normal normal normal 0.875rem Montserrat;
      }
    }
  }

  @media (max-width: 550px) {
    .followup-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .followup-info__name {
        margin-left: 1.5rem;
      }
    }
  }
`

export const BtnViewMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  button {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.blue._600};
    transition: 0.2s;
    cursor: pointer;

    span {
      margin-right: 0.25rem;
      border: 2px solid ${({ theme }) => theme.colors.blue._600};
      padding: 0.15rem 0.25rem;
      border-radius: 50%;

      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    &:hover {
      opacity: 0.9;
      font-weight: bold;
    }
  }
`

export const WrapperFollowupList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 14rem;
  width: 100%;
  padding-right: 0.5rem;
  overflow-y: scroll;
`
