import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getCards } from '../services/api'
import { CardModel } from '../entities'

export const useGetCardList = () => {
  const [isLoadingCardList, setIsLoadingCardList] = useState(false)
  const [cardList, setCardList] = useState<CardModel[] | []>([])

  async function getCardList () {
    setIsLoadingCardList(true)
    try {
      const response = await getCards()
      if (!response) {
        throw new Error()
      }
      setCardList(response)
    } catch (error: any) {
      if (error?.response?.status === 401) {
        toast.error('Ops 😥, expirou o tempo de sessão, por favor fazer login novamente')
        setTimeout(() => {
          sessionStorage.clear()
          localStorage.clear()
          window.location.href = '/login'
        }, 2000)
        return
      }
      toast.error('Ops 😥, ocorreu um error ao buscar os cards.')
    } finally {
      setIsLoadingCardList(false)
    }
  }

  useEffect(() => {
    getCardList()
  }, [])

  return {
    isLoadingCardList,
    cardList,
    getCardList
  }
}
