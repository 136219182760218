import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Header from './Header'

import { Container, ContainerFooter } from './styles'

type Props = {
  children: ReactNode
  title: string
  isOverflowY?: boolean
  isOneRowCard?: boolean
}

const Layout = ({ children, title, isOverflowY = false, isOneRowCard = false }: Props) => {
  const { pathname } = useLocation()
  const isHomePage = pathname === '/'

  useEffect(() => {
    document.title = title
  }, [title])

  return (
    <Container $isHomePage={isHomePage} $isOverflowY={isOverflowY}>
      <Header isOneRowCard={isOneRowCard} />
      {children}
      <ContainerFooter>
        <p>Poder Judiciário do Estado da Bahia - 2023. Todos os direitos reservados. Versão 3.0</p>
      </ContainerFooter>
    </Container>
  )
}

export default Layout
