import { Fragment } from 'react'
import { Card, Spinner } from '..'
import { CardModel } from '../../entities'
import { ContainerCards } from './styles'

type Props = {
  isOneRow?: boolean
  cardList: CardModel[]
  isLoadingCardList: boolean
}

const CardLits = ({ isOneRow = false, cardList, isLoadingCardList }: Props) => {
  return (
    <ContainerCards $isOneRow={isOneRow}>
      {isLoadingCardList && <Spinner size={25}/>}
      {!isLoadingCardList && cardList.length === 0 && <p>Nenhum card encontrado!</p>}
      {cardList.length > 0 && (
        <div className="cards-content">
          {cardList.map((card) => (
            <Fragment key={card.id}>
              {card.isEnable ? (
                <Card key={card.id} card={card} url={`/service/${card.id}`} />
              ) : null}
            </Fragment>
          ))}
        </div>
      )}
    </ContainerCards>
  )
}

export default CardLits
