/* eslint-disable react-hooks/exhaustive-deps */
import { ToastContainer } from 'react-toastify'
import AdminModal from 'components/Modal/ModalContent'
import { FollowupModel, ITicket } from '../../entities'
import FollowupItem from './FollowupItem'

import { WrapperFollowupList } from './styles'

type Props = {
  ticket: ITicket
  toggleModal: () => void
}

const FollowupListModal = ({ ticket, toggleModal }: Props) => {
  const handleCloseModal = () => {
    toggleModal()
  }

  return (
    <AdminModal
      title="Acompanhamento do chamado"
      handleCloseModal={handleCloseModal}
      handleSubmit={null}
      isLoading={false}
    >
      <WrapperFollowupList>
        {ticket?.followups?.all_followups.map((data: FollowupModel, index: number) => (
          <FollowupItem key={index} followup={data} />
        ))}
      </WrapperFollowupList>
      <ToastContainer />
    </AdminModal>
  )
}

export default FollowupListModal
