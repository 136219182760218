import { useGetCardList } from 'hooks'
import {
  CardLits,
  Carousel,
  Contacts,
  Layout,
  NewsCarousel,
  TicketList
} from '../../components'
import { useWindowDimensions } from '../../hooks/useWindowDimensions'

import { ContainerHome } from './styles'

export function Home () {
  const { width } = useWindowDimensions()
  const { cardList, isLoadingCardList } = useGetCardList()
  const widthScreenDefault = 1365 // 1365px
  const maxLengthCards = 5 * 2 // Table of 5 cards in 2 rows, for a 1365px wide screen to show on the home page
  const activeCards = cardList.filter((card) => card.isEnable)
  const isShowCarousel = width < widthScreenDefault || activeCards.length > maxLengthCards
  const isOneRowCard = activeCards.length <= maxLengthCards / 2

  return (
    <Layout title="Auto Atendimento" isOneRowCard={isOneRowCard}>
      <ContainerHome>
        {isShowCarousel ? (
          <Carousel
            cardList={cardList}
            isLoadingCardList={isLoadingCardList}
            isOneRow={isOneRowCard}
          />
        ) : (
          <CardLits
            cardList={cardList}
            isLoadingCardList={isLoadingCardList}
            isOneRow={isOneRowCard}
          />
        )}

        <section className="home-info">
          <TicketList />
          <NewsCarousel />
          <Contacts />
        </section>
      </ContainerHome>
    </Layout>
  )
}
