/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { FieldArray, FieldValues, PathValue, useFieldArray, useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { SurveyQuestionModel } from '../../../entities'

import {
  AddBtnAdmField,
  RemoveBtnAdmField,
  WrapperAdmField,
  WrapperAdmFieldList,
  WrapperFields
} from '../AttributeFields/styles'
import SurveyField from './SurveyField'
import { SurveyQuestionOptionsProps } from './SurveySelect'

export type ISurveyQuestionProps = Pick<
SurveyQuestionModel,
'question' | 'required' | 'scaleLimit' | 'type' | 'positionOrder' | 'label'
> & { options: string[] | null | SurveyQuestionOptionsProps[] }

type Props = {
  fieldName: any
}

const SurveyFieldList = <T extends FieldValues>({ fieldName }: Props) => {
  const { control, getValues, setValue } = useFormContext<T>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
    keyName: '_id'
  })

  const updateAttPosition = (attributes: T[]) => {
    const _attributes: T[] = []
    attributes.forEach((att, idx) => {
      _attributes.push({ ...att, positionOrder: idx + 1 })
    })
    return _attributes
  }

  const reorderList = (e: DragEndEvent) => {
    if (!e.over) return

    if (e.active.id !== e.over.id) {
      const oldIdx = fields.findIndex((el) => el._id === e.active.id)
      const newIdx = fields.findIndex((el) => el._id === e.over?.id)
      const _fields = arrayMove(getValues(fieldName), oldIdx, newIdx)
      const _newFields = updateAttPosition(_fields as T[])
      setValue(fieldName, _newFields as PathValue<T, any>)
    }
  }

  return (
    <WrapperFields>
      <label htmlFor="attributes">Crie novas perguntas:</label>
      <DndContext onDragEnd={reorderList} autoScroll={false}>
        <SortableContext items={fields.map((el) => el._id)}>
          <WrapperAdmFieldList>
            {fields.map((field, index) => (
              <WrapperAdmField key={field._id}>
                <SurveyField<T>
                  id={field._id}
                  attribute={field as unknown as SurveyQuestionModel}
                  question={`${fieldName}.${index}.question`}
                  type={`${fieldName}.${index}.type`}
                  positionOrder={`${fieldName}.${index}.positionOrder`}
                  required={`${fieldName}.${index}.required`}
                  scaleLimit={`${fieldName}.${index}.scaleLimit`}
                  options={`${fieldName}.${index}.options`}
                  index={index}
                />
                <RemoveBtnAdmField>
                  <button
                    type="button"
                    onClick={() => {
                      remove(index)
                    }}
                    title="Remover campo"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </RemoveBtnAdmField>
              </WrapperAdmField>
            ))}
          </WrapperAdmFieldList>
        </SortableContext>
      </DndContext>
      <AddBtnAdmField>
        <button
          type="button"
          onClick={() => {
            append({
              type: 'text',
              question: '',
              required: true,
              scaleLimit: 0,
              positionOrder: fields.length + 1,
              label: null,
              options: null
            } as FieldArray<T, any>)
          }}
          title="Adicionar novo campo"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <p>Adicionar pergunta</p>
      </AddBtnAdmField>
    </WrapperFields>
  )
}

export default SurveyFieldList
