import { OptionSelect } from 'components/Select/Select'

export const surveyTypeFieldOptions: OptionSelect[] = [
  { label: 'Estrela', value: 'stars', icon: 'fa-regular fa-star' },
  { label: 'Texto curto', value: 'text', icon: 'fa-solid fa-grip-lines' },
  { label: 'Paragrafo', value: 'textarea', icon: 'fa-solid fa-align-justify' },
  // { label: 'Seletor', value: 'select', icon: 'fa-regular fa-hand-pointer' },
  // { label: 'Checkboxes', value: 'checkbox', icon: 'fa-regular fa-square-check' },
  { label: 'Radio', value: 'radio', icon: 'fa-regular fa-circle-dot' }
]
