import { ChangeEvent, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel } from '../../entities'
import { ErrorMessage, FormImageFileField } from './styles'

type Props = {
  attribute: AttributeModel
  imageUrlData?: string
}
const ImageFileField = ({ attribute, imageUrlData = '' }: Props) => {
  const {
    control,
    setValue,
    clearErrors,
    setError,
    formState: { errors }
  } = useFormContext()
  const [image, setImage] = useState(imageUrlData)

  async function getImageSize (file: any) {
    const img = new Image()
    img.src = URL.createObjectURL(file)

    const promise = new Promise((resolve, reject) => {
      img.onload = () => {
        const width = img.naturalWidth
        const height = img.naturalHeight
        resolve({ width, height })
      }
      img.onerror = reject
    })

    return promise
  }

  const handleUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    clearErrors(attribute.id.toString())
    removeImage()
    if (!e.target.files) return
    if (e.target.files[0]) {
      const defaultFormats = ['jpg', 'png', 'jpeg']
      const isValidFormat = defaultFormats.includes(
        (e?.target?.files[0].name?.split('.') as unknown as any[]).pop()
      )

      if (!isValidFormat) {
        setError(attribute.id.toString(), { type: 'error', message: '* Formato inválido' })
        setValue(attribute.id.toString(), null)
        return
      }

      const imgDimensions: any = await getImageSize(e.target.files[0])
      const isValidImgSize = !!(imgDimensions.width >= 450 && imgDimensions.height >= 200)

      if (!isValidImgSize) {
        setError(attribute.id.toString(), {
          type: 'error',
          message: '* Largura e altura mínina deve ser 450x200'
        })
        return
      }

      setImage(URL.createObjectURL(e.target.files[0]))
      setValue(attribute.id.toString(), e.target.files[0])
    }
  }

  const validations = {
    required: {
      value: attribute.required,
      message: '* Campo obrigatório'
    }
  }

  const removeImage = () => {
    setImage('')
    setValue(attribute.id.toString(), '')
  }

  return (
    <Controller
      name={attribute.id.toString()}
      control={control}
      rules={validations}
      render={() => (
        <FormImageFileField>
          <div className="form-file">
            {!image && (
              <label className="form-file-input">
                <span>+</span>
                <span>Adicionar imagem</span>
                <input
                  type="file"
                  name={attribute.id.toString()}
                  onChange={handleUpload}
                  accept="image/jpeg, image/png, image/jpg"
                />
              </label>
            )}
            {image && (
              <div className="form-file-img">
                <button onClick={removeImage}>X</button>
                <img alt="preview image" src={image} />
              </div>
            )}
          </div>
          {errors[attribute.id.toString()] && (
            <ErrorMessage>{errors[attribute.id.toString()]?.message?.toString()}</ErrorMessage>
          )}
        </FormImageFileField>
      )}
    />
  )
}

export default ImageFileField
