import { memo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useGetColorFromTicketStatus } from '../../hooks'
import { ITicket } from '../../entities'
import {
  BottomContent,
  Container,
  Description,
  MiddleContent,
  Information,
  TopContent,
  LabelContent,
  TopInfo,
  TopDetails,
  BtnViewMore
} from './styles'
import FollowupListModal from './FollowupListModal'
import FollowupItem from './FollowupItem'

import { TicketStatus } from '../Home/styles'
import { ModalWrapper } from 'pages/Admin/styles'

type Props = {
  ticket: ITicket
}

const TicketInfo = ({ ticket }: Props) => {
  const { getColor } = useGetColorFromTicketStatus()
  const navigate = useNavigate()
  const refFollowupModal = useRef<HTMLDialogElement>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const noShowBtn =
    ticket?.status.toUpperCase() === 'ENCERRADO' ||
    ticket?.status.toUpperCase() === 'CANCELADO' ||
    ticket?.status.toUpperCase() === 'FINALIZADO POR PENDÊNCIA'
  const label = ticket?.status.toUpperCase() === 'RESOLVIDO' ? 'REABRIR' : 'ADICIONAR COMENTÁRIO'

  const handleRedirect = () => {
    if (ticket?.ticketID) {
      navigate(`/updateTicket/${ticket?.ticketID}`, {
        state: { status: ticket?.status }
      })
    }
  }

  const toggleFollowupModal = () => {
    if (refFollowupModal.current) {
      if (isOpenModal) {
        setIsOpenModal(false)
        refFollowupModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenModal(true)
      refFollowupModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  return (
    <Container>
      <TopContent>
        <TopInfo>
          <div className="wrapper-title">
            <span className="title">Início&nbsp;</span>
            {ticket?.categories.map((category) => (
              <span key={category} className="title">
                ▶&nbsp;{category}&nbsp;
              </span>
            ))}
          </div>
          <div className="id">
            <span>Chamado</span>
            <span>{ticket?.ticketID}</span>
          </div>
        </TopInfo>
        <LabelContent>{ticket?.title}</LabelContent>
        <TopDetails>
          <Information>
            <div className="status">
              <span>Status:</span>
              <TicketStatus $statusColor={getColor(ticket?.status)}>{ticket?.status}</TicketStatus>
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Data de abertura:</span>
              <span>{ticket?.createdAt}</span>
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Coordenação:</span>
              <span>{ticket?.group.replace(/_.*/, '')}</span>
            </div>
            <div className="line"></div>
            <div className="info">
              <span>Solicitante:</span>
              <span>{ticket?.requesterName}</span>
            </div>
          </Information>
          {!noShowBtn && (
            <button className="ticket-info-btn" onClick={handleRedirect}>
              {label}
            </button>
          )}
        </TopDetails>
      </TopContent>
      <MiddleContent>
        <LabelContent>DESCRIÇÃO DO CHAMADO</LabelContent>
        <Description>
          {ticket?.description.map((description) => (
            <span key={description} className="response">
              {description} <br />
              <br />
            </span>
          ))}
        </Description>
      </MiddleContent>
      <BottomContent>
        <LabelContent>ÚLTIMO ACOMPANHAMENTO</LabelContent>

        {ticket?.followups?.latest_followup ? (
          <FollowupItem followup={ticket.followups.latest_followup} />
        ) : (
          <span className="text">Este chamado ainda não tem acompanhamento.</span>
        )}

        {ticket?.followups?.all_followups && ticket?.followups?.all_followups?.length > 1 && (
          <BtnViewMore>
            <button onClick={toggleFollowupModal}>
              <span>
                <FontAwesomeIcon icon={faPlus} />
              </span>
              Ver mais
            </button>
          </BtnViewMore>
        )}

        <ModalWrapper ref={refFollowupModal} $width='70%'>
          {isOpenModal && <FollowupListModal ticket={ticket} toggleModal={toggleFollowupModal} />}
        </ModalWrapper>
      </BottomContent>
    </Container>
  )
}

export default memo(TicketInfo)
