import { ReactNode } from 'react'
import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom'
import {
  ConsultTicketList,
  Home,
  NewsAdmin,
  Service,
  SignIn,
  Success,
  TicketDetails,
  TicketForm,
  UpdateTicket,
  AdminManagement,
  ArticlesAdmin,
  CardAdmin,
  NotificationPage,
  FormsAdmin,
  AttributesAdmin,
  AddForm,
  EditForm,
  SurveyAdmin
} from './pages'

import { AuthProvider } from './contexts/auth'

type Props = {
  children: ReactNode
}

const Private = ({ children }: Props) => {
  if (!localStorage.getItem('token')) {
    return <Navigate to="/login" replace />
  }
  return children
}

export const AppRoutes = () => {
  return (
    <Router>
      <AuthProvider>

        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="/" element={<Private><Home /></Private>} />
          <Route path="/ticket/detail/:ticketID" element={<Private><TicketDetails /></Private>} />
          <Route path="/ticket/list" element={<Private><ConsultTicketList /></Private>} />
          <Route path="/ticket/success/:ticketId" element={<Private><Success /></Private>} />
          <Route path="/ticket/new/:formId" element={<Private><TicketForm /></Private>} />
          <Route path="/service/:cardId" element={<Private><Service /></Private>} />
          <Route path="/service/:cardIdL1/:cardId" element={<Private><Service /></Private>} />
          <Route path="/service/:cardIdL1/:cardIdL2/:cardId" element={<Private><Service /></Private>} />
          <Route path="/updateTicket/:ticketId" element={<Private><UpdateTicket /></Private>} />
          <Route path="/notification" element={<Private><NotificationPage /></Private>} />

          <Route path="/admin" element={<Private><AdminManagement /></Private>} />
l         <Route path="/admin/news" element={<Private><NewsAdmin /></Private>} />
          <Route path="/admin/faq" element={<Private><ArticlesAdmin /></Private>} />
          <Route path="/admin/card" element={<Private><CardAdmin /></Private>} />

          <Route path="/admin/card/:cardId" element={<Private><CardAdmin /></Private>} />
          <Route path="/admin/card/:cardIdL1/:cardId" element={<Private><CardAdmin /></Private>} />
          <Route path="/admin/card/:cardIdL1/:cardIdL2/:cardId" element={<Private><CardAdmin /></Private>} />

          <Route path="/admin/forms" element={<Private><FormsAdmin /></Private>} />
          <Route path="/admin/questions" element={<Private><AttributesAdmin /></Private>} />
          <Route path="/admin/new/form" element={<Private><AddForm /></Private>} />
          <Route path="/admin/edit/form/:formId" element={<Private><EditForm /></Private>} />

          <Route path="/admin/survey" element={<Private><SurveyAdmin /></Private>} />

        </Routes>
      </AuthProvider>
    </Router>
  )
}
